import React, { ReactElement } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
  .tooltipInner {
    display: none;
    position: absolute;
    background: #fff;
    color: #000;
    top: -120%;
    left: -100%;
    padding: 0 10px 5px 10px;
    border-radius: 5px;
  }
  &:hover {
    .tooltipInner {
      display: block;
    }
  }
`;
const Tooltip: React.FC<{
  children: ReactElement;
  title: string;
}> = ({ children, title }) => {
  return (
    <TooltipContainer>
      <div className="tooltipInner">{title}</div>
      <div>{children}</div>
    </TooltipContainer>
  );
};

export default Tooltip;
