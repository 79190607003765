import { formatUnits } from "ethers/lib/utils";
import { formatAmountUSD, formatVaultBalance } from "shared/lib/utils/math";
import {
  BlueSwanVaultAddressMap,
  formatPercentage,
} from "../../constants/constants";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import moment from "moment";
import { useWeb3Context } from "shared/lib/hooks/web3Context";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import React, { useEffect, useState } from "react";
import { fetchVaultSummaryData } from "../../hooks/useFetchBlueSwanData";
import { BlueSwanVaultData } from "../../store/types";
import {
  useAssetBalance,
  useV2VaultData,
} from "shared/lib/hooks/web3DataContext";
import useVaultActionForm from "../../hooks/useVaultActionForm";
import useVaultStrategyData from "shared/lib/hooks/useVaultStrategyData";
import useVaultFeeData from "shared/lib/hooks/useVaultFeeData";
import useFetchVaultDataHistorical from "shared/lib/hooks/useFetchVaultDataHistorical";
import { ethers } from "ethers";
import VaultPortfolioCompositionRow from "../Analytics/VaultPorfolioCompositionRow";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";
import HelpInfo from "shared/lib/components/Common/HelpInfo";

interface VaultSummaryInformationProps {
  network: string;
}

const VaultSummaryInformation: React.FC<VaultSummaryInformationProps> = ({
  network,
}) => {
  const defaultLabels = ["Mar", "May", "Jul", "Sep", "Nov", "Dec", "Jan"];
  const defaultValues = defaultLabels.map(() =>
    Math.floor(Math.random() * (280000 - 260000 + 1) + 260000)
  );

  //@ts-ignore
  const vaultData = BlueSwanVaultAddressMap[network];

  const [error, setError] = useState(false);
  const [positions, setPositions] = useState([]);
  const [portfolioRiskMetrics, setPortfolioRiskMetrics] = useState({} as any);

  const [incomeGenerated, setIncomeGenerated] = useState(0);

  const {
    data: {
      asset,
      pricePerShare,
      numPositions,
      cap,
      decimals,
      depositBalanceInAsset,
      lockedBalanceInAsset,
      round,
      totalBalance,
      withdrawals,
    },
    loading,
  } = useV2VaultData(vaultData.symbol);

  const { responses: strategyData, loading: loadingStrategies } =
    useVaultStrategyData(vaultData.symbol);

  const { responses: feeData, loading: loadingFees } = useVaultFeeData(
    vaultData.symbol
  );
  const { handleActionTypeChange, vaultActionForm, handleMaxClick } =
    useVaultActionForm(vaultData.symbol);
  const { balance: userAssetBalance } = useAssetBalance(
    vaultActionForm.depositAsset || asset
  );

  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [totalValuePosition, setTotalValuePosition] = useState(0);

  useEffect(() => {
    // console.log('Network changed', network)
    async function fetch() {
      const { loading, error, data } = await fetchVaultSummaryData(network);
      if (!error && data) {
        /* Set Vault Data */
        setVData(data);

        /* Set Positions Data */
        setPositions(data.positions);
        setPortfolioRiskMetrics(data.portfolioRiskMetrics);
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }

    fetch();
  }, [vdata, network, loadingStrategies]);

  const [combinedStrategyData, setCombinedStrategyData] = useState<any[]>();

  useEffect(() => {
    if (!loadingStrategies) {
      const combinedData = [];

      // @ts-ignore
      for (const [index, strategy] of strategyData.entries()) {
        let protocol =
          positions.length > index
            ? // @ts-ignore
              positions[index]._source.lp_pair_protocol.split("-")[1]
            : "-";
        if (network === "optimism") {
          protocol = "Uniswap V3";
        }
        const apy =
          // @ts-ignore
          positions.length > index ? positions[index]._source.total_apy : 0;
        combinedData.push({
          token0: positions[index]
            ? // @ts-ignore
              positions[index]._source.borrowing_data_token_0.underlying_symbol
            : strategy.stableTokenName, //strategy.stableTokenName,
          token1: positions[index]
            ? // @ts-ignore
              positions[index]._source.borrowing_data_token_1.underlying_symbol
            : strategy.volatileTokenName, //strategy.volatileTokenName,
          protocol,
          apy,
          deposit: strategy.equity, // - strategy.debtUsd,
          totalDeposit: strategyData.reduce(
            (acc: any, curr: any) => acc + curr.equity, //- curr.debtUsd,
            0
          ),
          balanceOfPool: parseFloat(
            formatUnits(strategy.balanceOfPool || 0, 6)
          ),
        });
      }
      setCombinedStrategyData(combinedData);
    }
  }, [positions, strategyData, network, loadingStrategies]);

  useEffect(() => {
    const stableSupplied = strategyData.reduce(
      (acc, curr) => acc + curr.stableSuppliedUsd,
      0
    );
    const stableWithdrawn = strategyData.reduce(
      (acc, curr) => acc + curr.stableWithdrawnUsd,
      0
    );
    const totalEquity = strategyData.reduce(
      (acc, curr) => acc + curr.equity,
      0
    );
    const totalDebt = strategyData.reduce((acc, curr) => acc + curr.debtUsd, 0);
    const income = totalEquity + stableWithdrawn - stableSupplied;
    setIncomeGenerated(income);
  }, [strategyData]);

  const date = new Date();
  date.setDate(date.getDate() - 1);
  const { response: dataDayAgo, loading: dataDayAgoLoading } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );
  date.setDate(date.getDate() - 6);
  const { response: dataWeekAgo, loading : dataWeekAgoLoading } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );
  date.setDate(date.getDate() - 23);
  const { response: dataMonthAgo, loading: dataMonthAgoLoading } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );

  const performance1Day = dataDayAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataDayAgo.data.pricePerShare)
        .mul(10000)
        .div(dataDayAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performance1Week = dataWeekAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataWeekAgo.data.pricePerShare)
        .mul(10000)
        .div(dataWeekAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performance1Month = dataMonthAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataMonthAgo.data.pricePerShare)
        .mul(10000)
        .div(dataMonthAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performanceBeginning =
    pricePerShare
      ?.sub(ethers.utils.parseUnits("1", 18))
      .mul(10000)
      .div(ethers.utils.parseUnits("1", 18))
      .toNumber() / 100;

  const getChainImage = (chain: string) => {
    let img;
    if (chain.toLowerCase() === "optimism") img = Optimism;
    else if (chain.toLowerCase() === "fantom") img = Fantom;
    else img = Avalanche;

    return img;
  };

  const vaultInformation = [
    { title: "Vault Symbol", value: vaultData.symbol },
    {
      title: "Inception Date",
      value: vdata && vdata.strategySummary.days_elapsed,
    },
    { title: "Asset Exposure", value: "US Dollars" },
    { title: "Vault Type", value: "US$ PSEUDO DELTA NEUTRAL FARMING" },
    {
      title: "Management Fee",
      value: `${formatUnits(feeData.adminFee || 0, 12)} %`,
    },
    {
      title: "Performance Fee",
      value: `${formatUnits(feeData.performanceFee || 0, 12)} %`,
    },
    {
      title: "Withdrawal Fee",
      value: `${formatUnits(feeData.withdrawalFee || 0, 13)} %`,
    },
  ];

  const portfolioPerformance = [
    {
      title: "Last 24 hours ",
      value: `${performance1Day}%`,
    },
    {
      title: "Last 7 days ",
      value: `${performance1Week}%`,
    },
    {
      title: "Last 30 days ",
      value: `${performance1Month}%`,
    },
    {
      title: "Since Inception",
      value: `${performanceBeginning}%`,
    },
    {
      title: "Income Generated Since Inception",
      value: "$" + incomeGenerated.toFixed(1),
    },
  ];

  const vaultQuotedNetApy = [
    { title: "Current net APY", value: formatPercentage(vdata?.apy) },
    { title: "24 hour average net APY", value: formatPercentage(vdata?.apy24) },
    { title: "7 day average net APY", value: formatPercentage(vdata?.apy7d) },
    { title: "14 day average net APY", value: formatPercentage(vdata?.apy14d) },
  ];

  const vaultMetrics = [
    {
      title: "Vault Price",
      value: Number(formatUnits(pricePerShare, decimals + 12)).toFixed(2),
    },
    {
      title: "Vault Capacity",
      value: `${
        loadingStrategies
          ? "loading..."
          : formatAmountUSD(strategyData.length * 150000)
      }`,
    },
    {
      title: "Vault Deposits",
      value: `$ ${(+formatUnits(totalBalance || 0, 6)).toFixed(2)}`,
    },
    { title: "Positions Open", value: numPositions?.toNumber() || 0 },
  ];

  const riskMetrics = [
    {
      title: "Rebalances Since Inception",
      value: vdata && vdata.strategySummary.rebalances,
    },
    {
      title: "Cvar",
      value:
        portfolioRiskMetrics && portfolioRiskMetrics.CVaR_99
          ? (portfolioRiskMetrics.CVaR_99 * 100).toFixed(2).toString() + "%"
          : 0,
    },
    {
      title: "Sharpe Ratio",
      value:
        portfolioRiskMetrics && portfolioRiskMetrics.sharpe_ratio
          ? portfolioRiskMetrics.sharpe_ratio.toFixed(2)
          : 0,
    },
    {
      title: "Max Drawdown",
      value:
        portfolioRiskMetrics && portfolioRiskMetrics.maxDrawDown
          ? (portfolioRiskMetrics.maxDrawDown * 100).toFixed(2).toString() + "%"
          : 0,
    },
    {
      title: "Annual Volatility",
      value:
        portfolioRiskMetrics && portfolioRiskMetrics.annual_volatility
          ? portfolioRiskMetrics.annual_volatility.toFixed(2).toString() + "%"
          : 0,
    },
  ];
  return (
    <>
      <div className="row my-3">
        <div className="col-12">
          <h5 className="text-uppercase font-weight-600 d-inline mr-3">
            Vault composition
          </h5>
        </div>
        <div className="col-12">
          {!loadingStrategies ? (
            <table className="bs-table">
              <tr>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"Liquidity Pool"}</span>
                    <TooltipExplanation
                      title="Liquidity Pool"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"TVL via Blue Swan"}</span>
                    <TooltipExplanation
                      title="TVL via Blue Swan"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"% Allocation"}</span>
                    <TooltipExplanation
                      title="% Allocation"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"Net APY"}</span>
                    <TooltipExplanation
                      title="Net APY"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"Leverage"}</span>
                    <TooltipExplanation
                      title="Leverage"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"Lending Source"}</span>
                    <TooltipExplanation
                      title="Lending Source"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{"Protocol"}</span>
                    <TooltipExplanation
                      title="Protocol"
                      explanation=""
                      renderContent={({ ref, ...triggerHandler }) => (
                        <HelpInfo containerRef={ref} {...triggerHandler}>
                          i
                        </HelpInfo>
                      )}
                    />
                  </div>
                </th>
              </tr>

              {combinedStrategyData &&
                combinedStrategyData.map((data: any) => {
                  return (
                    <VaultPortfolioCompositionRow
                      token1Name={data.token0}
                      token2Name={data.token1}
                      protocol={data.protocol}
                      apy={data.apy}
                      deposit={data.balanceOfPool}
                      totalDeposit={data.totalDeposit}
                    />
                  );
                })}
            </table>
          ) : (
            <>Loading ...</>
          )}
        </div>
      </div>
      <div className="row vault-analytics-grid">
        <div className="col-12">
          <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
            <div className="col-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h5 className="text-center">Vault Information</h5>
                    </div>
                  </div>

                  {vaultInformation.map((d) => {
                    return (
                      <>
                        <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                          <div className="col-md-7">
                            <h6>{d.title}</h6>
                          </div>
                          <div className="col-md-5 text-md-right">
                            <h5 className="text-uppercase">{d.value}</h5>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="row mb-3">
                    <div className="col-12">
                      <h5 className="text-center">Portfolio Performance</h5>
                    </div>
                  </div>

                  { !(dataDayAgoLoading && dataWeekAgoLoading && dataMonthAgoLoading) ?  portfolioPerformance.map((d) => {
                    return (
                      <>
                        <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                          <div className="col-md-7">
                            <h6>{d.title}</h6>
                          </div>
                          <div className="col-md-5 text-md-right">
                            <h5 className="text-uppercase">{d.value}</h5>
                          </div>
                        </div>
                      </>
                    );
                  }) : <>
                  Loading...
                  </>}
                </div>

                <div className="col-md-6">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h5 className="text-center">Vault quoted net APY</h5>
                    </div>
                  </div>

                  {vaultQuotedNetApy.map((d) => {
                    return (
                      <>
                        <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                          <div className="col-md-7">
                            <h6>{d.title}</h6>
                          </div>
                          <div className="col-md-5 text-md-right">
                            <h5 className="text-uppercase">{d.value}</h5>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="row mb-3">
                    <div className="col-12">
                      <h5 className="text-center">Vault metrics</h5>
                    </div>
                  </div>

                  {vaultMetrics.map((d) => {
                    return (
                      <>
                        <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                          <div className="col-md-7">
                            <h6>{d.title}</h6>
                          </div>
                          <div className="col-md-5 text-md-right">
                            <h5 className="text-uppercase">{d.value}</h5>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className="row mb-3">
                    <div className="col-12">
                      <h5 className="text-center">Risk metrics</h5>
                    </div>
                  </div>

                  {riskMetrics.map((d) => {
                    return (
                      <>
                        <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                          <div className="col-md-7">
                            <h6>{d.title}</h6>
                          </div>
                          <div className="col-md-5 text-md-right">
                            <h5 className="text-uppercase">{d.value}</h5>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VaultSummaryInformation;
