import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import USD from "../../assets/icons/blueswan/usd.svg";
import ETH from "../../assets/icons/blueswan/eth.svg";
import Matic from "../../assets/icons/blueswan/matic.svg";
import Avax from "../../assets/icons/blueswan/avax.svg";
// import fetchBlueSwanData from "../../hooks/useFetchBlueSwanData";
import DepositModal from "../Deposit/DepositModal";
import VaultDetailRow from "./VaultDetailRow";
import CapacityBar from "../Common/CapacityBar";
import { Link } from "react-router-dom";
import ExternalLink from "shared/lib/assets/icons/externalLink";
import { BaseLink, BaseUnderlineLink } from "shared/lib/designSystem";
import Tooltip from "../Tooltip";
import { fetchStrategyData } from "../../hooks/useFetchBlueSwanData";
import { BlueSwanVaultData } from "../../store/types";
import addresses from "shared/lib/constants/externalAddresses.json";
import { CHAINID } from "shared/lib/utils/env";

interface VaultDetailProps {
  network: string;
}

const VaultDetail: React.FC<VaultDetailProps> = ({ network }) => {
  const [error, setError] = useState(false);
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedVault, setSelectedVault] = useState<string>("");
  const [vaultNetwork, setSelectedVaultNetwork] = useState<string>("");

  const [highestAPY, setHighestAPY] = useState(0);
  const [lowestAPY, setLowestAPY] = useState(0);

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchStrategyData();
      if (!error && data) {
        setVData(data);

        const highest = data.strategyApyRange.reduce(
          (previous: any, current: any) => {
            return current.weighted_apy.value > previous.weighted_apy.value
              ? current
              : previous;
          }
        );
        const lowest = data.strategyApyRange.reduce(
          (previous: any, current: any) => {
            return current.weighted_apy.value < previous.weighted_apy.value
              ? current
              : previous;
          }
        );
        setHighestAPY(parseInt((highest.weighted_apy.value * 100).toFixed(1)));
        setLowestAPY(parseInt((lowest.weighted_apy.value * 100).toFixed(1)));
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }
    if (!vdata) {
      fetch();
    }
  }, [vdata]);
  return (
    <div className="container my-5 pt-5">
      <DepositModal
        vaultFetchedData={vdata}
        network={vaultNetwork}
        selectedVault={selectedVault}
        show={showDepositModal}
        onClose={() => {
          setShowDepositModal(false);
        }}
      />
      {/* Heading */}
      {/* <div className="row section-heading">
        <div className="col-md-6">
          <div className="row">
            <div className="col-12">
              <div className="tag">VAULTS / SWAN 01</div>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-12">
              <h1 className="heading">Swan Vaults</h1>
            </div>
          </div>
        </div>
      </div> */}
      {/* Heading */}

      {/* Vault Switcher */}
      {/* <div className="row">
        <div className="col-12 text-center text-md-left">
          <div className="bs-tab-btn active my-2">
            <a href="#">SWAN 01 / MARKET NEUTRAL FARMING</a>
            <div className="highlighted"></div>
          </div>
          <div className="bs-tab-btn my-2 link-disabled">
            <a href="#" className="link-disabled">
              Swan 02 / Long-Only Yield
            </a>
            <div className="highlighted"></div>
          </div>
        </div>
      </div> */}
      {/* Vault Switcher */}

      {/* Strategy Description */}

      <div className="row my-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <div className="tag mb-3">SWAN 01</div>
              <div className="tag">Market Neutral</div>
              <h2 className="mt-2 mb-4">US$ Pseudo Delta Neutral Farming</h2>
              <p>
                Below you will find the different Vaults that are performing the
                Pseudo Delta Neutral farming strategy. There is a Vault per
                blockchain, so you can choose to invest using your favourite
                chain(s). Instead of listing all the liquidity pools separately,
                we created a portfolio combining a number of liquidity pools so
                you don’t have to be spending time managing your positions or
                switching your allocation to other pools that perform better. We
                do that for you, using optimised allocation based on different
                risk and return parameters ran by our Quant team.
              </p>
              <p>
                Please note that for this strategy to work well, you would want
                to leave your deposits for at least one month to allow the
                compounding to start happening.
              </p>

              <p>
                If you want to learn more about our Pseudo Delta Neutral
                strategy, please visit our {' '}
                {
                  <BaseUnderlineLink
                    target={"_blank"}
                    to={
                      "https://blue-swan.gitbook.io/blue-swan-documentation/swan-vaults-portfolios/swan-vaults-01-leveraged-pseudo-market-neutral-farming"
                    }
                  >
                    DOCUMENTATION
                    <ExternalLink
                      style={{
                        marginLeft: 6,
                      }}
                    />
                  </BaseUnderlineLink>
                }
              </p>
            </div>

            <div className="col-md-5 offset-md-1">
              <div className="box bg radius p-4 mt-md-5">
                <div className="row no-gutters box bg box-clean box-items">
                  <div className="col-md-6 p-3 box-item">
                    <div className="row">
                      <div className="col-12 box-heading">Blockchains</div>
                      <div className="col-12 py-3 text-center">
                        <Tooltip title="Avalanche">
                          <img
                            src={Avalanche}
                            className="mr-1"
                            width="30"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip title="Optimism">
                          <img
                            src={Optimism}
                            className="mr-1"
                            width="30"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip title="Fantom">
                          <img
                            src={Fantom}
                            className="mr-1"
                            width="30"
                            alt=""
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 p-3 box-item">
                    <div className="row">
                      <div className="col-12 box-heading">Asset Exposure</div>
                      <div className="col-12 py-3 text-center">
                        <img src={USD} width={30} className="mb-2 mr-2" />
                        <h4 className="text-uppercase d-md-inline pt-1">
                          US Dollar
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 py-3 box-item box-border-top">
                    <div className="row">
                      <div className="col-12 box-heading">
                        APY Range (last 24 hours){" "}
                      </div>
                      <div className="col-12 pt-3 text-center">
                        <h4 className="text-uppercase">
                          {lowestAPY}%
                          <small>to</small>
                          {highestAPY}%
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12">
                    <span className="box-grey-copy">
                      This strategy performs better during LOW PRICE VOLATILITY
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Strategy Description */}

      {/* Catalog Heading */}
      <div className="row">
        <div className="col-12 my-4">
          <h6 className="d-inline-block mr-3 text-uppercase">SWAN01 VAULTS </h6>
          {/* <div className="tag">CATALOG</div> */}
        </div>
      </div>
      {/* Catalog Heading */}

      <VaultDetailRow
        network="avalanche"
        tags={["SWAN 01", "US$ Pseudo Delta Neutral Farming"]}
        onDeposit={() => {
          setSelectedVaultNetwork("avalanche");
          setSelectedVault("S01AVAX");
          setShowDepositModal(!showDepositModal);
        }}
      />

      <VaultDetailRow
        network={`fantom`}
        tags={["SWAN 01", "US$ Pseudo Delta Neutral Farming"]}
        onDeposit={() => {
          setSelectedVaultNetwork("fantom");
          setSelectedVault("S01FTM");
          setShowDepositModal(!showDepositModal);
        }}
      />

      <VaultDetailRow
        network="optimism"
        tags={["SWAN 01", "US$ Pseudo Delta Neutral Farming"]}
        onDeposit={() => {
          setSelectedVaultNetwork("optimism");
          setSelectedVault("S01OP");
          setShowDepositModal(!showDepositModal);
        }}
      />
    </div>
  );
};

export default VaultDetail;
