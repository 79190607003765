import React from "react";
import styled from "styled-components";
import { BaseUnderlineLink, PrimaryText } from "shared/lib/designSystem";
import PendingTransactionLoader from "shared/lib/components/Common/PendingTransactionLoader";
import {
  getExplorerName,
  getExplorerURI,
  Chains,
} from "shared/lib/constants/constants";
import { useChain } from "shared/lib/hooks/chainContext";

const TransactionPendingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  margin-bottom: 24px;
`;

const BottomTextContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
`;

const BottomText = styled(PrimaryText)`
  color: rgba(255, 255, 255, 0.8);
`;

interface TransactionStepProps {
  txhash?: string;
  color?: string;
  step?: number;
}
const TxAnimation = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 40px auto;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #3E73C4;
    border-color: #3E73C4 transparent #3E73C4 transparent;
    animation: loading-animation 1.2s linear infinite;
  }
  @keyframes loading-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const TransactionStep: React.FC<TransactionStepProps> = ({
  txhash,
  color,
  step,
}) => {
  const [chain] = useChain();
  console.log("TransactionStep hash", txhash);
  console.log("TransactionStep step", step);
  return (
    <>
    <TxAnimation />
      {/* <TransactionPendingContainer>
        <PendingTransactionLoader
          // active={Boolean(txhash)}
          active={true}
          numberOfBars={3}
          color={color}                    
        />
      </TransactionPendingContainer> */}
      <BottomTextContainer>
        {chain !== Chains.NotSelected && txhash ? (
          <BaseUnderlineLink
            to={`${getExplorerURI(chain)}/tx/${txhash}`}
            target="_blank"
            rel="noreferrer noopener"
            className="d-flex"
          >
            {<BottomText>View on {getExplorerName(chain)}</BottomText>}
          </BaseUnderlineLink>
        ) : (
          <BottomText>Confirm this transaction in your wallet</BottomText>
        )}
      </BottomTextContainer>
    </>
  );
};

export default TransactionStep;
