import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useV2VaultsData } from "shared/lib/hooks/web3DataContext";
import { formatBigNumber } from "shared/lib/utils/math";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Optimism from "../../assets/icons/blueswan/optimism.svg";


const options = {
  plugins: {

  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem:any, data:any) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var currentValue = dataset.data[tooltipItem.index];
        return currentValue + '%';
      },
      title: function(tooltipItem:any, data:any) {
        return data.labels[tooltipItem[0].index];
      }
    }
  },
  legend: {
    display: false,
  },
};

const getUserBalance = (lockedBalanceInAsset:any, pricePerShare:any):Number => 
{
  return lockedBalanceInAsset
  ? Number(
      formatBigNumber(
        lockedBalanceInAsset.mul(pricePerShare).div(1e12),
        12
      )
    )
  : 0;
}
interface PortfolioValueProps {}
const PortfolioAllocation: React.FC<PortfolioValueProps> = ({}) => {
  const { data: vaultsData } = useV2VaultsData();
  const [total, setTotal] = useState(0);
  const [avalanche, setAvalanche] = useState(0);
  const [fantom, setFantom] = useState(0);
  const [optimism, setOptimism] = useState(0);
  const getAllocation = (alloc:any, total:any) => {
    return total> 0 ? ((alloc / total) * 100).toFixed(3) : 0
  }
  const filterElements2 = (
    <>
      <div className="row">
        <div className="col-12 py-4">
          <div className="filterElement green text-left px-3">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(189, 247, 255, 1)" }}
            ></span>
            <img className="mr-2" src={Avalanche} />
            Avalanche / {parseInt( '' + getAllocation(avalanche, total))}%
          </div>
  
          <div className="filterElement green text-left px-3">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(189, 247, 255, .6)" }}
            ></span>
            <img className="mr-2" src={Fantom} />
            Fantom {parseInt('' + getAllocation(fantom, total))}%
          </div>
          <div className="filterElement green text-left px-3">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(189, 247, 255, .3)" }}
            ></span>
            <img className="mr-2" src={Optimism} />
            Optimism {parseInt('' + getAllocation(optimism, total))}%
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    let total = 0;
    for (const [key, value] of Object.entries(vaultsData)) {
      //@ts-ignore
      total += getUserBalance(value.lockedBalanceInAsset,value.pricePerShare);
        
    }
    // @ts-ignore
    setAvalanche(getUserBalance(vaultsData.S01AVAX.lockedBalanceInAsset, vaultsData.S01AVAX.pricePerShare).toFixed(1));
    // @ts-ignore
    setFantom(getUserBalance(vaultsData.S01FTM.lockedBalanceInAsset, vaultsData.S01FTM.pricePerShare).toFixed(1));

    // @ts-ignore
    setOptimism(getUserBalance(vaultsData.S01OP.lockedBalanceInAsset, vaultsData.S01OP.pricePerShare).toFixed(1));
    
    setTotal(total);
  }, [vaultsData]);

  const data = {
    labels: ["Swan 01 Avalanche", "Swan 01 Fantom", "Swan 01 Optimism"],
    datasets: [
      {
        label: "TVL Concentration",
        data: [Math.round((avalanche / total) * 100), Math.round((fantom/total) * 100), Math.round((optimism/total) * 100)],
        backgroundColor: [
          "rgba(189, 247, 255, 1)",
          "rgba(189, 247, 255, .6)",
          "rgba(189, 247, 255, .3)",
        ],
        borderWidth: 0,
      },
    ],
  };
  return (
    <>
      <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-md-12 mb-2">
              <h6 className="text-uppercase d-inline mr-3">
                PORTFOLIO ALLOCATION BY CHAIN
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Doughnut options={options} data={data} />
            </div>
            <div className="col-md-5">{filterElements2}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioAllocation;
