import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import sizes from "shared/lib/designSystem/sizes";
import theme from "shared/lib/designSystem/theme";
import useScreenSize from "shared/lib/hooks/useScreenSize";
import useVaultOption from "../../hooks/useVaultOption";
import AccountStatus from "../Wallet/AccountStatus";
import DesktopFooter from "./DesktopFooter";
import { useState } from "react";
import { useGlobalState } from "shared/lib/store/store";
import HeaderLogo from "../Header/HeaderLogo";
import discord from "../../assets/img/discord-icon.png";
import twitter from "../../assets/img/tw-icon.png";
import { Link, useHistory } from "react-router-dom";
import { BaseLink } from "shared/lib/designSystem";

const FooterContainer = styled.div<{
  screenHeight: number;
  showVaultPosition: boolean;
}>`
  height: ${theme.footer.desktop.height}px;
  width: 100%;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(40px);
  /**
   * Firefox desktop come with default flag to have backdrop-filter disabled
   * Firefox Android also currently has bug where backdrop-filter is not being applied
   * More info: https://bugzilla.mozilla.org/show_bug.cgi?id=1178765
   **/
  @-moz-document url-prefix() {
    background-color: rgba(0, 0, 0, 0.9);
  }

  ${(props) => `
    position: sticky;
    top: calc(${props.screenHeight ? `${props.screenHeight}px` : `100%`} - ${
    theme.footer.desktop.height
  }px);
  `}

  @media (max-width: ${sizes.md}px) {
    position: fixed;
    top: unset;
    bottom: 0px;
    height: ${(props) =>
      props.showVaultPosition
        ? theme.footer.mobile.heightWithPosition
        : theme.footer.mobile.height}px;
    z-index: 5;
  }
`;

const MobileFooterOffsetContainer = styled.div<{ showVaultPosition: boolean }>`
  @media (max-width: ${sizes.md}px) {
    height: ${(props) =>
      props.showVaultPosition
        ? theme.footer.mobile.heightWithPosition
        : theme.footer.mobile.height}px;
  }
`;

const Footer = () => {
  const { height: screenHeight } = useScreenSize();
  const { vaultOption, vaultVersion } = useVaultOption();
  const [showVaultPosition, setShowVaultPosition] = useState(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const [, setComponentRefs] = useGlobalState("componentRefs");

  const [isWhiteHeader, setIsWhiteHeader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsWhiteHeader(history.location.pathname.includes("institutional"));
  }, [history.location.pathname]);

  useEffect(() => {
    if (footerRef.current) {
      setComponentRefs((prev) => ({
        ...prev,
        footer: footerRef.current as HTMLDivElement,
      }));
    }
  }, [footerRef, setComponentRefs]);

  return (
    <>
      <footer className={`w-100 py-4 flex-shrink-0 ${isWhiteHeader ? 'institutional-footer' : ''}`}>
        <div className="footer-line"></div>
        <div className="container py-4">
          <div className="row gy-4 gx-5">
            <div className="col-lg-3 col-md-6">
              <div className="mb-4">
                <HeaderLogo />
              </div>

              <h4 className="text-white text-uppercase mb-5">
                Your gateway to accessing decentralized financial markets.
              </h4>
              <div className="social-items mb-3">
                <BaseLink
                  className="mr-2"
                  to={"https://twitter.com/BlueSwan_io"}
                  target="_blank"
                >
                  <img src={twitter} alt="" />
                </BaseLink>
                <BaseLink
                  className="mr-2"
                  to={"https://discord.gg/T7vkh84Nfg"}
                  target="_blank"
                >
                  <img src={discord} alt="" />
                </BaseLink>
              </div>
            </div>
            <div className="col-4 col-lg-2 col-md-6 offset-lg-3">
              <h5 className="text-white mb-3">Features</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={"portfolio"}>Portfolio</Link>
                </li>
                <li>
                  <Link to={"analytics"}>Analytics</Link>
                </li>
              </ul>
            </div>
            <div className="col-4 col-lg-2 col-md-6">
              <h5 className="text-white mb-3">Features</h5>
              <ul className="list-unstyled">
                {/* <li>
                  <a href="#">Covered Call</a>
                </li> */}
                <li>
                  <a href="#">Stables</a>
                </li>
                <li>
                  <a href="#">Crab</a>
                </li>
                {/* <li>
                  <a href="#">Basis Yield</a>
                </li> */}
                {/* <li>
                  <a href="#">Capital Protection</a>
                </li> */}
              </ul>
            </div>
            <div className="col-4 col-lg-2 col-md-6">
              <h5 className="text-white mb-3">Community</h5>
              <ul className="list-unstyled">
                <li>
                  <BaseLink
                    to={"https://blue-swan.gitbook.io/blue-swan-documentation/"}
                    target="_blank"
                  >
                    Docs
                  </BaseLink>
                </li>
                <li>
                  <a href="#">Forum</a>
                </li>
                <li>
                  <a href="#">Blogs</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-line"></div>

        <div className="container pt-4">
          <div className="row">
            <div className="col-md-6">
              <a href="#" className="mr-3">
                ABOUT
              </a>
              <a href="https://blue-swan.gitbook.io/blue-swan-documentation/swan-vaults-portfolios/faq" target="_blank" className="mr-3">
                FAQ
              </a>
              <a href="#" className="mr-3">
                CONTACT
              </a>
            </div>
            <div className="col-md-6 text-md-right text-uppercase">
              <p>© 2022 Blue Swan. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
