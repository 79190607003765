import OverviewMenu from "../../components/Analytics/OverviewMenu";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import TimeChart from "../../components/Chart/TimeChart";
import TimeChartDualAxis from "../../components/Chart/TimeChartDualAxis";
import VaultPortfolioCompositionRow from "../../components/Analytics/VaultPorfolioCompositionRow";
import LendingSourceSample from "../../assets/img/lending-src-sample.svg";
import Avax from "../../assets/icons/blueswan/avax.svg";
import Usdce from "../../assets/icons/blueswan/usdce.svg";
import TraderJoe from "../../assets/icons/blueswan/traderjoe.svg";
import Pangolin from "../../assets/icons/blueswan/pangolin-2.svg";
import SpookySwap from "../../assets/icons/blueswan/spooky.svg";
import SpiritSwap from "../../assets/icons/blueswan/spiritswap.svg";
import CapacityBar from "../../components/Common/CapacityBar";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";
import HelpInfo from "shared/lib/components/Common/HelpInfo";
import { useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import DepositModal from "../../components/Deposit/DepositModal";
import Tooltip from "../../components/Tooltip";
import {
  BlueSwanVaultAddressMap,
  formatPercentage,
  LEVERAGE,
} from "../../constants/constants";
import { useWeb3Context } from "shared/lib/hooks/web3Context";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import { _abi } from "../../abis/blueswanVault";
import { BigNumber, Contract, ethers } from "ethers";
import {
  formatAmountUSD,
  formatVaultBalance,
  getPercentageChange,
} from "shared/lib/utils/math";
import {
  BlueSwanVaultData,
  BlueSwanPortfolioBacktesting,
} from "../../store/types";
import {
  fetchVaultSummaryData,
  fetchPortfolioBacktestng,
} from "../../hooks/useFetchBlueSwanData";
import moment from "moment";
import {
  useAssetBalance,
  useV2VaultData,
  useV2VaultsData,
} from "shared/lib/hooks/web3DataContext";
import useVaultActionForm from "../../hooks/useVaultActionForm";
import { formatUnits } from "ethers/lib/utils";
import useVaultStrategyData from "shared/lib/hooks/useVaultStrategyData";
import useVaultFeeData from "shared/lib/hooks/useVaultFeeData";
import useFetchVaultDataHistorical from "shared/lib/hooks/useFetchVaultDataHistorical";
import VaultSummaryInformation from "../../components/Vault/VaultSummaryInformation";

const defaultLabels = ["Mar", "May", "Jul", "Sep", "Nov", "Dec", "Jan"];
const defaultValues = defaultLabels.map(() =>
  Math.floor(Math.random() * (280000 - 260000 + 1) + 260000)
);

function getFormattedDate(date: Date) {
  date = new Date(date);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  // return month + "-" + day + "-" + year.toString().slice(-2);
  return moment(date).format("d MMMM YY");
}

const VaultSummaryPage = () => {
  const network =
    useRouteMatch<{ vault: string }>(
      "/vault/:vault/"
    )?.params.vault.toLocaleLowerCase() || "avalanche";

  //@ts-ignore
  const vaultData = BlueSwanVaultAddressMap[network];

  const [error, setError] = useState(false);
  const [vaultBalance, setVaultBalance] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);

  const [backtestingChartSource, setBacktestingChartSource] = useState([]);
  const [backtestingChartLabels, setBacktestingChartLabels] = useState([]);
  const [backtestingChartPrice, setBacktestingChartPrice] = useState([]);
  const [portfolioRiskMetrics, setPortfolioRiskMetrics] = useState({} as any);

  const [incomeGenerated, setIncomeGenerated] = useState(0);

  const {
    data: {
      asset,
      pricePerShare,
      numPositions,
      cap,
      decimals,
      depositBalanceInAsset,
      lockedBalanceInAsset,
      round,
      totalBalance,
      withdrawals,
    },
    loading,
  } = useV2VaultData(vaultData.symbol);

  const { responses: strategyData, loading: loadingStrategies } =
    useVaultStrategyData(vaultData.symbol);

  const { handleActionTypeChange, vaultActionForm, handleMaxClick } =
    useVaultActionForm(vaultData.symbol);
  const { balance: userAssetBalance } = useAssetBalance(
    vaultActionForm.depositAsset || asset
  );

  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [backTesting, setPortfolioBacktesting] = useState<
    BlueSwanPortfolioBacktesting | undefined
  >();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [totalValuePosition, setTotalValuePosition] = useState(0);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchVaultSummaryData(network);
      if (!error && data) {
        /* Set Vault Data */
        setVData(data);

        /* Set Positions Data */
        setPositions(data.positions);
        setPortfolioRiskMetrics(data.portfolioRiskMetrics);

        /* Parse APY Chart  */
        /* if (data.apyChart) {
                let labels: any = [];
                let source: any = [];
                data.apyChart.map((item: any) => {
                  let d = new Date(item.key);
                  let fDate = moment(d).format("MMM D");
                  labels.push(fDate);
                  source.push(Number(item.weighted_apy.value).toFixed(4));
                });
                setChartApyLabels(labels);
                setChartApySource(source);
              } */
        /* Parse APY Chart  */
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }

    fetch();
  }, [vdata, network]);

  useEffect(() => {
    console.log(
      "Vault Data",
      depositBalanceInAsset,
      lockedBalanceInAsset,
      totalBalance
    );
    setVaultBalance(formatVaultBalance(totalBalance));
    setAccountBalance(formatVaultBalance(userAssetBalance));
  }, [vdata, network]);

  useEffect(() => {
    const stableSupplied = strategyData.reduce(
      (acc, curr) => acc + curr.stableSuppliedUsd,
      0
    );
    const stableWithdrawn = strategyData.reduce(
      (acc, curr) => acc + curr.stableWithdrawnUsd,
      0
    );
    const totalEquity = strategyData.reduce(
      (acc, curr) => acc + curr.equity,
      0
    );
    const totalDebt = strategyData.reduce((acc, curr) => acc + curr.debtUsd, 0);
    const income = totalEquity + stableWithdrawn - stableSupplied;
    setIncomeGenerated(income);
  }, [strategyData]);

  const date = new Date();
  date.setDate(date.getDate() - 1);
  const { response: dataDayAgo } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );
  date.setDate(date.getDate() - 6);
  const { response: dataWeekAgo } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );
  date.setDate(date.getDate() - 23);
  const { response: dataMonthAgo } = useFetchVaultDataHistorical(
    vaultData.symbol,
    date.toISOString()
  );

  const performance1Day = dataDayAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataDayAgo.data.pricePerShare)
        .mul(10000)
        .div(dataDayAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performance1Week = dataWeekAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataWeekAgo.data.pricePerShare)
        .mul(10000)
        .div(dataWeekAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performance1Month = dataMonthAgo.data.pricePerShare.gt(0)
    ? pricePerShare
        ?.sub(dataMonthAgo.data.pricePerShare)
        .mul(10000)
        .div(dataMonthAgo.data.pricePerShare)
        .toNumber() / 100
    : 0;
  const performanceBeginning =
    pricePerShare
      ?.sub(ethers.utils.parseUnits("1", 18))
      .mul(10000)
      .div(ethers.utils.parseUnits("1", 18))
      .toNumber() / 100;

  useEffect(() => {
    async function fetch() {
      let vSymbol: string =
        vaultData.symbol == "S01OP" ? "S01OPT" : vaultData.symbol;
      const { loading, error, data } = await fetchPortfolioBacktestng(vSymbol);
      console.log("data", data);
      if (!error && data) {
        /* Set Vault Data */
        setPortfolioBacktesting(data?.getBacktest);

        /* Parse Chart  */
        if (data.getBacktest?.backTestingData) {
          let labels: any = [];
          let source: any = [];
          let price: any = [];
          data.getBacktest?.backTestingData.map((item: any) => {
            let d = new Date(item.date);
            let fDate = moment(d).format("MMM D");
            labels.push(fDate);
            source.push(Number(item.cummulative_return).toFixed(4));
            price.push(Number(item.token1_price).toFixed(4));
          });
          setBacktestingChartLabels(labels);
          setBacktestingChartSource(source);
          setBacktestingChartPrice(price);
        }
        /* Parse APY Chart  */
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }

    fetch();
  }, [backTesting, network]);

  const getChainImage = (chain: string) => {
    let img;
    if (chain.toLowerCase() === "optimism") img = Optimism;
    else if (chain.toLowerCase() === "fantom") img = Fantom;
    else img = Avalanche;

    return img;
  };

  const backtestData1 = [
    {
      title: "Optimization Methodology",
      value: backTesting?.optimizationMethodology,
    },
    {
      title: "Annualized Return",
      value: `${
        backTesting?.annualizedReturn ? backTesting?.annualizedReturn * 100 : 0
      }%`,
    },
    {
      title: "Sharpe Ratio",
      value: `${
        backTesting?.sharpeRatio ? backTesting?.sharpeRatio * 100 : 0
      }%`,
    },
    {
      title: "Number of Rebalances",
      value: `${
        backTesting?.numberOfRebalances ? backTesting?.numberOfRebalances : 0
      }`,
    },
  ];
  const backtestData2 = [
    {
      title: "Time Range",
      value: `${
        backTesting?.begginingDate
          ? getFormattedDate(backTesting?.begginingDate)
          : ""
      } / ${
        backTesting?.begginingDate
          ? getFormattedDate(backTesting?.begginingDate)
          : ""
      }`,
    },
    {
      title: "Annual Volatility",
      value: `${
        backTesting?.annualVolatility
          ? (backTesting?.annualVolatility * 100).toFixed(2)
          : 0
      }%`,
    },
    {
      title: "Mean Drawdown",
      value: `${
        backTesting?.meanDrawDown ? backTesting?.meanDrawDown * 100 : 0
      }%`,
    },
  ];

  return (
    <>
      <DepositModal
        network={network}
        show={showDepositModal}
        onClose={() => {
          setShowDepositModal(false);
        }}
      />

      <div className="container py-5">
        {/* Heading */}
        <div className="row section-heading pb-5">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div className="tag">VAULTS / Analytics</div>
              </div>
            </div>
            {/* <div className="row py-4">
              <div className="col-12">
                <h1 className="heading">Swan Analytics</h1>
              </div>
            </div> */}
          </div>
        </div>
        {/* Heading */}

        <div className="row">
          <div className="col-md-2">
            <OverviewMenu />
          </div>
          <div className="col-md-8 offset-md-1 mx-auto">
            <div className="row">
              <div className="col-md-8">
                <h2>
                  SWAN01 / <span className="text-capitalize">{network}</span>{" "}
                  Summary
                </h2>
              </div>
              <div className="col-md-4 pt-2 text-md-right">
                <img className="mr-2" src={getChainImage(network)} />
                <h6 className="d-inline text-uppercase">
                  <span className="text-capitalize">{network}</span>
                </h6>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-11 mx-auto col-md-12">
                    <div className="row box bg radius py-3 box-items px-2 my-3">
                      <div className="col-12">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <div className="tag vault my-2">SWAN 01</div>
                            <div className="tag vault my-2">
                              US$ Pseudo Delta Neutral Farming
                            </div>
                          </div>
                          <div className="col-md-6 text-md-right">
                            <div className="tag investment mr-auto">
                              Your investment:{" "}
                              <span>
                                $
                                {parseInt(formatUnits(lockedBalanceInAsset, 6))}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-4 col-md-3 ">
                                <div className="row">
                                  <div className="col-12 box-heading pb-3">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <span>{"Chain"}</span>
                                      <TooltipExplanation
                                        title="Chain"
                                        explanation=""
                                        renderContent={({
                                          ref,
                                          ...triggerHandler
                                        }) => (
                                          <HelpInfo
                                            containerRef={ref}
                                            {...triggerHandler}
                                          >
                                            i
                                          </HelpInfo>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <Tooltip title={network}>
                                      <img
                                        src={getChainImage(network)}
                                        className="mr-1 d-inline-block"
                                        width="20"
                                        alt=""
                                      />
                                    </Tooltip>

                                    <h6 className="text-uppercase d-inline">
                                      {network}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div className="col-4 col-md-2 text-center px-3">
                                <div className="row">
                                  <div className="col-12 box-heading pb-3">
                                    
                                    <div className="d-flex align-items-center justify-content-center">
                                      <span>{"Net APY:"}</span>
                                      <TooltipExplanation
                                        title="Net APY"
                                        explanation=""
                                        renderContent={({
                                          ref,
                                          ...triggerHandler
                                        }) => (
                                          <HelpInfo
                                            containerRef={ref}
                                            {...triggerHandler}
                                          >
                                            i
                                          </HelpInfo>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <h6 className="text-uppercase">
                                      {vdata && formatPercentage(vdata.apy)}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 col-md-3 text-center px-3">
                                <div className="row">
                                  <div className="col-12 box-heading pb-3">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <span>{"Target Leverage"}</span>
                                      <TooltipExplanation
                                        title="Target Leverage"
                                        explanation=""
                                        renderContent={({
                                          ref,
                                          ...triggerHandler
                                        }) => (
                                          <HelpInfo
                                            containerRef={ref}
                                            {...triggerHandler}
                                          >
                                            i
                                          </HelpInfo>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <h6 className="text-uppercase">3X</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 text-center px-3 ">
                                <div className="row">
                                  <div className="col-12 box-heading pb-2">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <span>{"TVL / Capacity"}</span>
                                      <TooltipExplanation
                                        title="TVL / Capacity"
                                        explanation=""
                                        renderContent={({
                                          ref,
                                          ...triggerHandler
                                        }) => (
                                          <HelpInfo
                                            containerRef={ref}
                                            {...triggerHandler}
                                          >
                                            i
                                          </HelpInfo>
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div className="px-1 mx-auto">
                                    {!loadingStrategies ? (
                                      <CapacityBar
                                        loading={false}
                                        current={parseInt(
                                          `${vaultBalance * LEVERAGE}`
                                        )}
                                        cap={strategyData.length * 150000}
                                        labelConfig={{
                                          fontSize: 12,
                                        }}
                                        statsConfig={{
                                          fontSize: 14,
                                        }}
                                        barConfig={{
                                          height: 4,
                                          extraClassNames: "my-2 mx-auto",
                                          radius: 2,
                                        }}
                                      />
                                    ) : (
                                      <>Loading...</>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-center text-md-right pt-2">
                            <button
                              onClick={() =>
                                setShowDepositModal(!showDepositModal)
                              }
                              className="bs-btn blue d-inline-block px-4 w-100"
                            >
                              DEPOSIT
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {network === "fantom" && (
                <div className="col-md-12">
                  {/* <p>
                    The S01FTM portfolio vault combines a series of pseudo
                    market neutral farming strategies within the Fantom
                    Ecosystem.{" "}
                  </p>
                  <p>
                    Within each strategy, we provide liquidity to a pool that
                    has a pair of a stable asset and FTM, and we use a Leverage
                    Yield Platform to obtain leverage on that LP position and at
                    the same time short the volatile asset exposure.
                  </p>
                  <p>
                    When the volatile token moves in price, and because the pool
                    needs to keep the 50%-50% parity, the number of unit tokens
                    of each pairs change.{" "}
                  </p>
                  <p>
                    Therefore, in order to keep the delta neutral position, a
                    rebalance is needed to adjust the volatile long position
                    versus the volatile short. We perform a series of rebalances
                    for each stratey using our optimisation algorith, that takes
                    into account exposure risk and mean reverting speed of the
                    volatile token price.
                  </p>
                  <p>
                    For more information about the mechanics of the rebalance
                    please visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/swan-vaults-portfolios/swan-vaults-01-leveraged-pseudo-market-neutral-farming#hedging-rebalancing-system"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p>
                  <p>
                    For More information abour Risk and Risk management, please
                    visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/risk-management/"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p> */}
                </div>
              )}

              {network === "avalanche" && (
                <div className="col-md-12">
                  {/* <p>
                    The S01AVAX portfolio vault combines a series of pseudo
                    market neutral farming strategies within the Avalanche
                    Ecosystem.
                  </p>
                  <p>
                    Within each strategy, we provide liquidity to a pool that
                    has a pair of a stable asset and AVAX, and we use a Leverage
                    Yield Platform to obtain leverage on that LP position and at
                    the same time short the volatile asset exposure.
                  </p>
                  <p>
                    When the volatile token moves in price, and because the pool
                    needs to keep the 50%-50% parity, the number of unit tokens
                    of each pairs change.
                  </p>
                  <p>
                    Therefore, in order to keep the delta neutral position, a
                    rebalance is needed to adjust the volatile long position
                    versus the volatile short. We perform a series of rebalances
                    for each stratey using our optimisation algorith, that takes
                    into account exposure risk and mean reverting speed of the
                    volatile token price.
                  </p>
                  <p>
                    For more information about the mechanics of the rebalance
                    please visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/swan-vaults-portfolios/swan-vaults-01-leveraged-pseudo-market-neutral-farming#hedging-rebalancing-system"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p>
                  <p>
                    For More information abour Risk and Risk management, please
                    visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/risk-management/"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p> */}
                </div>
              )}

              {network === "optimism" && (
                <div className="col-md-12">
                  {/* <p>
                    The S01OP portfolio vault combines a series of pseudo market
                    neutral farming strategies within the Optimism Ecosystem.{" "}
                  </p>
                  <p>
                    Within each strategy, we provide liquidity to a pool that
                    has a pair of a stable asset and ETH, and we use a Leverage
                    Yield Platform to obtain leverage on that LP position and at
                    the same time short the volatile asset exposure.
                  </p>
                  <p>
                    When the volatile token moves in price, and because the pool
                    needs to keep the 50%-50% parity, the number of unit tokens
                    of each pairs change.{" "}
                  </p>
                  <p>
                    Therefore, in order to keep the delta neutral position, a
                    rebalance is needed to adjust the volatile long position
                    versus the volatile short. We perform a series of rebalances
                    for each stratey using our optimisation algorith, that takes
                    into account exposure risk and mean reverting speed of the
                    volatile token price.
                  </p>
                  <p>
                    For more information about the mechanics of the rebalance
                    please visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/swan-vaults-portfolios/swan-vaults-01-leveraged-pseudo-market-neutral-farming#hedging-rebalancing-system"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p>
                  <p>
                    For More information abour Risk and Risk management, please
                    visit:{" "}
                    <a
                      href="https://blue-swan.gitbook.io/blue-swan-documentation/risk-management/"
                      target="_blank"
                    >
                      DOCS
                    </a>
                  </p> */}
                </div>
              )}
            </div>

            {/* <div className="row mb-3">
              <div className="col-12">
                <TimeChart
                  labels={chartApyLabels}
                  source={chartApySource}
                  title="Strategy performance"
                  tags={[vaultData.symbol]}
                  showFilters={false}
                />
              </div>
            </div> */}

            <VaultSummaryInformation network={network} key={network} />

            {/* 

<div className="row">
            <div className="col-md-12">
              <h5 className="text-uppercase d-inline mr-3">{props.title}</h5>
              {props.tags &&
                props.tags.map((t) => {
                  return <div className="tag d-inline">{t}</div>;
                })}
            </div>
          </div>
*/}

            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <h2 className="font-weight-600">Backtest Analysis</h2>
                  </div>
                  <div className="col-12">
                    <h6 className="text-uppercase mb-4 font-weight-600">
                      Methodology
                    </h6>
                    <p>
                      The {vaultData.symbol} portfolio vault combines a series
                      of pseudo market neutral farming strategies within the{" "}
                      {backTesting?.chain
                        ? backTesting?.chain.charAt(0)?.toUpperCase() +
                          backTesting?.chain.slice(1)
                        : ""}{" "}
                      Ecosystem.{" "}
                    </p>
                    <p>
                      Within each strategy, we provide liquidity to a pool that
                      has a pair of a stable asset and{" "}
                      {backTesting?.volatileAsset}, and we use a Leverage Yield
                      Platform to obtain leverage on that LP position and at the
                      same time short the volatile asset exposure.
                    </p>
                    <p>
                      The following chart shows our backtesting performance over
                      a period of time. Additional to this backtesting we run
                      other optimizations to determine what are the best
                      parameters to rebalance both the strategies and portfolio.
                      The allocation in each of the pools is determined by a
                      Risk Parity analysis.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-12">
                <TimeChartDualAxis
                  labels={backtestingChartLabels}
                  source={backtestingChartSource}
                  source2={backtestingChartPrice}
                  title="Backtest analysis"
                  subtitle={`Vault Performance and ${backTesting?.volatileAsset} Price`}
                  tags={[vaultData.symbol]}
                  showFilters={false}
                  volatileAsset={backTesting?.volatileAsset}
                  twoAxes={true}
                />
              </div>
            </div>

            <div className="row vault-analytics-grid">
              <div className="col-12">
                <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        {backtestData1.map((d) => {
                          return (
                            <>
                              <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                                <div className="col-md-7">
                                  <h6>{d.title}</h6>
                                </div>
                                <div className="col-md-5 text-md-right">
                                  <h5 className="text-uppercase">{d.value}</h5>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      <div className="col-md-6">
                        {backtestData2.map((d) => {
                          return (
                            <>
                              <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 px-3 my-3 analytics-row">
                                <div className="col-md-6">
                                  <h6>{d.title}</h6>
                                </div>
                                <div className="col-md-6 text-md-right">
                                  <h5 className="text-uppercase">{d.value}</h5>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VaultSummaryPage;
