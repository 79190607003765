import "./main.scss";
import Hero from "../../assets/img/institutional/institutional-hero.png";
import MarketResearchIcon from "../../assets/img/institutional/market-research-icon.svg";
import InfrastructureIcon from "../../assets/img/institutional/infrastructure-icon.svg";
import OperationsIcon from "../../assets/img/institutional/operations-icon.svg";
import MainImage from "../../assets/img/institutional/img-1.png";
import Strategies from "../../assets/img/institutional/img-2.png";
import Infrastructure from "../../assets/img/institutional/img-3.png";
import Portfolio from "../../assets/img/institutional/img-4.png";
import Analytics from "../../assets/img/institutional/img-3.png";
import RiskManagement from "../../assets/img/institutional/img-4.png";
import Bullet from "../../assets/img/institutional/bullet.svg";
import Contact from "../../assets/img/institutional/i-contact-img.png";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { blueSwanClient } from "../../apollo/client";

const SUBMIT_FORM = gql`
  mutation sendInstitutionalEmail($input: SendInstitutionalEmailInput!) {
    sendInstitutionalEmail(input: $input) {
      success
      message
    }
  }
`;

const Institutional = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM, {
    client: blueSwanClient,
  });

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    company: "",
    jobtitle: "",
    business: "",
    email: "",
    message: "",
  });
  const [emailResponse, setEmailResponse] = useState("");

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    submitForm({
      variables: {
        input: formData,
      },
    });
  };

  useEffect(() => {
    if (!loading) {
      if (data) {
        if (data.sendInstitutionalEmail.success) {
          setFormData({
            firstname: "",
            lastname: "",
            company: "",
            jobtitle: "",
            business: "",
            email: "",
            message: "",
          });
          setEmailResponse("Email sent successfully");
        } else {
          setEmailResponse("An error occurred, please try again later");
        }
      }
    }
  }, [loading, data]);

  return (
    <>
      <div className="institutional-wrapper">
        {/* <!-- Hero --> */}
        <div className="bs-i-hero pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-2 order-md-1">
                <div className="row">
                  <div className="col-12">
                    <h1 className="mt-md-5">
                      <span className="blue-text bs-text-underlined d-inline-block pb-2">
                        INSTITUTIONAL
                      </span>
                      <br />
                      ACCESS TO INVESTING <br />
                      IN DeFi
                    </h1>
                    <p className="mt-4 pr-md-5">
                      Our Enterprise solution offers a complete on-chain asset
                      management product suite: Secure access and management of
                      composed DEFI automated strategies, customisable
                      portfolios and full end to end risk management and
                      operations services
                    </p>
                  </div>
                  <div className="col-9 mx-auto col-md-12">
                    <p className="mt-3 mt-md-3">
                      <a
                        href="#contact"
                        className="i-rounded-btn d-inline-block text-center i-contact-btn"
                      >
                        Contact Us
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
                <img src={Hero} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Hero --> */}

        {/* <!-- Section Animation --> */}
        <div className="main-animated-section">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row no-gutters">
                  <div className="col-md-5 text-md-right">
                    <h2>The platform for</h2>
                  </div>
                  <div className="col-md-7 text-left pl-md-2">
                    <div className="position-relative">
                      <div className="content">
                        <div className="content__container">
                          <ul className="content__container__list text-uppercase">
                            <li className="content__container__list__item">
                              HEDGE FUNDS
                            </li>
                            <li className="content__container__list__item">
                              ASSET MANAGERS
                            </li>
                            <li className="content__container__list__item">
                              HNWIS
                            </li>
                            <li className="content__container__list__item">
                              FINANCIAL INSTITUTIONS
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Section Animation --> */}

        {/* <!-- Blue Reactangle with Animation --> */}
        {/* <div
          className="secondary-animated-section bs-i-light-section"
          data-aos="fade-up"
        >
          <div className="container py-5">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="row no-gutters">
                  <div className="col-md-12 text-center">
                    <h2>More opportunities that just</h2>
                  </div>
                  <div className="col-md-12 mt-3 mt-md-5">
                    <div className="row">
                      <div className="col-md-3">
                        <h3 className="blue-text text-center">
                          buy and hold a crypto asset
                        </h3>
                      </div>
                      <div className="col-md-3">
                        <h3 className="blue-text text-center">
                          Liquidity farming
                        </h3>
                      </div>
                      <div className="col-md-3">
                        <h3 className="blue-text text-center">Staking</h3>
                      </div>
                      <div className="col-md-3">
                        <h3 className="blue-text text-center">Lending</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- Blue Reactangle with Animation --> */}

        {/* <!-- Three boxes --> */}
        <div className="bs-i-boxes-section" data-aos="fade-up">
          <div className="container">
            <div className="bs-dashed-wrapper my-5">
              <div className="bs-dashed-container pt-5">
                <div className="row">
                  <div className="col-md-8 mx-auto text-center">
                    <h1 className="text-center text-uppercase mb-3">
                      THERE ARE A LOT OF <br /> INVESTMENT OPPORTUNITIES
                      <span className="blue-text bs-text-underlined d-inline-block pb-3 pl-2">
                        IN DEFI
                      </span>
                    </h1>
                  </div>
                  <div className="col-11 col-md-8 text-center mx-auto">
                    <p>
                      But if you are a
                      <strong className="blue-text px-1">
                        Wealth Manager, Hedgefund, Asset Manager, Family office
                        or HNWI
                      </strong>
                      that is interested in investing in DeFi, you will need to:
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 px-4 py-5 text-center">
                    <img
                      src={MarketResearchIcon}
                      width="96"
                      className="img-fluid mb-3"
                      alt=""
                    />
                    <p>Have the technical and market research and knowledge</p>
                  </div>
                  <div className="col-md-4 px-4 py-5 text-center">
                    <img
                      src={InfrastructureIcon}
                      width="96"
                      className="img-fluid mb-3"
                      alt=""
                    />
                    <p>
                      Spend a considerable amount of time and money to set up
                      the infrastructure to deploy investment strategies
                    </p>
                  </div>
                  <div className="col-md-4 px-4 py-5 text-center">
                    <img
                      src={OperationsIcon}
                      width="96"
                      className="img-fluid mb-3"
                      alt=""
                    />
                    <p>
                      Deploy more resources to manage and maintain the
                      infrastructure and operations
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Three boxes --> */}

        {/* <!-- Copy Block --> */}
        <div className="bs-i-light-section">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-8 mx-auto text-center">
                <h1 className="text-uppercase blue-text">
                  Blue Swan solves
                  <span className="bs-text-underlined d-inline-block pl-2 pb-2">
                    this problem
                  </span>
                </h1>
                <p>
                  End to end non-custodial platform that facilitates investing
                  in Defi
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Copy Block --> */}

        {/* <!-- Blue Section with Image --> */}
        <div className="bs-i-blue-section py-5">
          <div className="container-fluid">
            <div className="row mb-md-5">
              <div className="col-11 col-md-8 mx-auto my-5">
                <h1 className="text-center text-uppercase white-text">
                  <span className="white-text">With</span> OUR ENTERPRISE
                  SOLUTION,
                  <span className="bs-text-underlined-white white-text d-inline-block pb-2">
                    our clients can
                  </span>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-11 mx-auto col-md-9 offset-md-3">
                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">01</span>
                      </div>
                      <div>
                        <h3>
                          Access to a library of automated yield strategies that
                          cover different market conditions and risk profiles
                        </h3>
                        <ul>
                          <li>Market neutral</li>
                          <li>Directional</li>
                          <li>Non-correlated</li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">02</span>
                      </div>
                      <div>
                        <h3>
                          Create on chain portfolios based on Smart contract
                          vaults combining the strategies of your choosing
                        </h3>
                        <ul>
                          <li>
                            Optimal allocation based on backtests and live
                            on-chain simulations
                          </li>
                          <li>
                            The vaults have whitelist function, so only the
                            addresses you approve can interact with them
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">03</span>
                      </div>
                      <div>
                        <h3>
                          Operation management of Blue Swan for monitoring and
                          implementation of risk controls
                        </h3>
                        <ul>
                          <li>
                            Risk framework to determine exposure to different
                            assets, protocols and chains
                          </li>
                          <li>Live risk monitoring</li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">04</span>
                      </div>
                      <div>
                        <h3>Access to real time dashboards and analytics</h3>
                        <ul>
                          <li>Ops management dashdoards (god view)</li>
                          <li>NAV calculations</li>
                          <li>Individual account reporting</li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">05</span>
                      </div>
                      <div>
                        <h3>All managed with MPC wallet infrastructure</h3>
                        <ul>
                          <li>
                            Non custodial infrastructure so you manage your own
                            keys in a secure way (No FTX!)
                          </li>
                          <li>
                            Increase or reduce positions without restrictions
                          </li>
                          <li>
                            Your own interface to interact with your portfolios
                          </li>
                          <li>
                            Detailed information of trades, NAVs for tax
                            purposes
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-start justify-center steps-row mb-3"
                      data-aos="fade-down"
                    >
                      <div className="pr-4 position-relative">
                        <span className="number-step">06</span>
                      </div>
                      <div>
                        <h3>A dedicated account management team</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 right-img" data-aos="fade-left">
                <img src={MainImage} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Blue Section with Image --> */}

        {/* <!-- Alternative blocks --> */}
        {/* <div className="bs-i-alternative-blocks py-5">
          <div className="container-fluid">
            <div className="row my-5">
              <div className="col-11 col-md-7 mx-auto pb-5">
                <h1 className="text-center text-capitalize blue-text">
                  We have developed a risk framework that we apply to
                  <span className="blue-text bs-text-underlined d-inline-block pl-2 pb-2">
                    every process.
                  </span>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-11 col-md-12 mx-auto">
                <div
                  className="bs-i-box bg-circles-right"
                  data-aos="fade-right"
                >
                  <div className="row">
                    <div className="col-md-6 py-3 py-md-0">
                      <img src={Strategies} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4 offset-md-1 pr-md-5">
                      <div className="h-100">
                        <div className="row align-items-center h-100">
                          <div className="col-12 mx-auto">
                            <h2 className="blue-text">Strategies</h2>
                            <p>
                              We take all the pain of designing, managing, and
                              maintaining Defi investment infrastructure
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bs-i-box bg-circles" data-aos="fade-left">
                  <div className="row">
                    <div className="order-2 offset-md-1 col-md-4 pl-md-5">
                      <div className="h-100">
                        <div className="row align-items-center h-100">
                          <div className="col-12 mx-auto mt-md-5">
                            <h2 className="blue-text">Infrastructure</h2>
                            <p className="border-red">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Earum quam, ducimus assumenda perferendis
                              quaerat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 order-1 order-md-2 py-3 py-md-0">
                      <img src={Infrastructure} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="bs-i-box bg-circles-right-red"
                  data-aos="fade-right"
                >
                  <div className="row">
                    <div className="col-md-7 py-3 py-md-0">
                      <img src={Portfolio} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4 pl-md-5">
                      <div className="h-100">
                        <div className="row align-items-center h-100">
                          <div className="col-12 mx-auto mt-md-5">
                            <h2 className="blue-text">Portfolio Management</h2>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Earum quam, ducimus assumenda perferendis
                              quaerat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bs-i-box bg-circles" data-aos="fade-left">
                  <div className="row">
                    <div className="offset-md-1 col-md-4 pl-md-5 order-2 order-md-1">
                      <div className="h-100">
                        <div className="row align-items-center h-100">
                          <div className="col-12 mx-auto mt-md-5">
                            <h2 className="blue-text">Analytics</h2>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Earum quam, ducimus assumenda perferendis
                              quaerat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7 py-3 py-md-0 order-1 order-md-2">
                      <img src={Analytics} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="bs-i-box bg-circles-bottom"
                  data-aos="fade-right"
                >
                  <div className="row">
                    <div className="col-md-7 py-3 py-md-0">
                      <img src={RiskManagement} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4 pl-md-5">
                      <div className="h-100">
                        <div className="row align-items-center h-100">
                          <div className="col-12 mx-auto mt-md-5">
                            <h2 className="blue-text">Risk Management</h2>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Earum quam, ducimus assumenda perferendis
                              quaerat.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <!-- Alternative blocks --> */}

        {/* <!-- Bullet related items --> */}
        <div className="bs-i-light-section">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="text-uppercase text-center blue-text">
                  Key advantages of our end to end platform
                </h2>
              </div>
            </div>

            <div className="row mt-5 no-gutters">
              <div className="col-md-3">
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Multichain{" "}
                </div>
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Composable{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Programmable / Automated Vaults{" "}
                </div>
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Risk Managed{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Secure{" "}
                </div>
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Transparent{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Easy to Use{" "}
                </div>
                <div className="my-2">
                  <img src={Bullet} width="25" alt="" className="mr-2" />
                  Scalable
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Bullet related items --> */}

        {/* <!-- Contact Form --> */}
        <div className="bs-i-contact py-5" id="contact">
          <div className="container-fluid py-5">
            <div className="row">
              <div className="col-11 col-md-12 mx-auto">
                <div className="row">
                  <div className="col-md-5 offset-md-1">
                    <h2 className="white-text">Connect with Us</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <label>First Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstname"
                            required
                            value={formData.firstname}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Company Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            required
                            value={formData.company}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Last Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastname"
                            required
                            value={formData.lastname}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Job Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="jobtitle"
                            value={formData.jobtitle}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Email *</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Business Sector</label>
                          <input
                            type="text"
                            className="form-control"
                            name="business"
                            value={formData.business}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-12">
                          <label>Message</label>
                          <textarea
                            className="form-control"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          >
                            {" "}
                          </textarea>
                        </div>

                        <div className="col-12 text-center pt-3">
                          <button className="i-rounded-btn i-white-btn d-inline-block text-center">
                            Send Message
                          </button>
                        </div>

                        <div className="col-12">
                          {loading && (
                            <div
                              className="alert alert-info mt-3"
                              role={"alert"}
                            >
                              Sending...
                            </div>
                          )}
                          {data && emailResponse !== "" && (
                            <div
                              className={`alert alert-${
                                data.sendInstitutionalEmail.success
                                  ? "success"
                                  : "danger"
                              } mt-3" role="alert`}
                            >
                              {emailResponse}
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6">
                    <img src={Contact} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Contact Form --> */}

        {/* <!-- Footer (app) --> */}
      </div>
    </>
  );
};

export default Institutional;
