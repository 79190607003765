import CookieConsent from "react-cookie-consent";

export const CookieBanner: React.FC = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Allow cookies"
        cookieName={"Blue_Swan_Protocol"}
        enableDeclineButton
        declineButtonText="Decline"
        style={{
          background: "#253357",
          color: "#e9eaec",
          fontSize: "1rem",
        }}
        contentStyle={{ margin: "0 1em" }}
        buttonStyle={{
          background: "#253357",
          color: "#fff",
          fontSize: "1rem",
          margin: 0,
          padding: ".8em 1.8em",
          fontWeight: 500,
        }}
        declineButtonStyle={{
          background: "#e9eaec",
          color: "#253357",
          fontSize: "1rem",
          margin: 0,
          padding: ".8em 1.8em",
          fontWeight: 500,
        }}
      >
        This website uses cookies to ensure you get the best experience on our
        website.
      </CookieConsent>
    </>
  );
};


export default CookieBanner;