import Avax from "../../assets/icons/blueswan/avax.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import Usdce from "../../assets/icons/blueswan/usdce.svg";
import Usdc from "../../assets/icons/blueswan/usdc.svg";
import Usdt from "../../assets/icons/blueswan/usdt.svg";
import Eth from "../../assets/icons/blueswan/eth.svg";
import Dai from "../../assets/icons/blueswan/dai.svg";
import TraderJoe from "../../assets/icons/blueswan/traderjoe.svg";
import Uniswap from "../../assets/icons/blueswan/uniswap.svg";
import Pangolin from "../../assets/icons/blueswan/pangolin-2.svg";
import SpookySwap from "../../assets/icons/blueswan/spooky.svg";
import SpiritSwap from "../../assets/icons/blueswan/spiritswap.svg";
import HomoraIcon from "../../assets/img/homora-icon.png";
import { formatAmountUSD } from "shared/lib/utils/math";
import Tooltip from "../Tooltip";
import { useEffect, useState } from "react";
import { formatPercentage } from "../../constants/constants";

const protocolAssets = {
  "": <></>,
  "Trader Joe": <img width={20} src={TraderJoe} alt="" />,
  "Spooky Swap": <img width={20} src={SpookySwap} alt="" />,
  "Spirit Swap": <img width={20} src={SpiritSwap} alt="" />,
  "Uniswap V3": <img width={20} src={Uniswap} alt="" />,
  Pangolin: <img width={20} src={Pangolin} alt="" />,
};

const getTokenAsset = (key: string) => {
  if (key.includes("ftm") || key.includes("fantom")) {
    return Fantom;
  }
  if (key.includes("usdc")) {
    return Usdc;
  }
  if (key.includes("usdt")) {
    return Usdt;
  }
  if (key.includes("opt")) {
    return Optimism;
  }
  if (key.includes("usdce")) {
    return Usdce;
  }
  if (key.includes("avax")) {
    return Avax;
  }
  if (key.includes("eth")) {
    return Eth;
  }
  if (key.includes("dai")) {
    return Dai;
  }
};
const getProtocolAsset = (key: string) => {
  if (key.includes("joe")) {
    return "Trader Joe";
  }
  if (key.includes("spooky")) {
    return "Spooky Swap";
  }
  if (key.includes("spirit")) {
    return "Spirit Swap";
  }
  if (key.includes("pangolin")) {
    return "Pangolin";
  }
  if (key.includes("uniswap")) {
    return "Uniswap V3";
  }
  return "";
};

interface VaultPortfolioCompositionRowProps {
  protocol: string;
  token1Name: string;
  token2Name: string;
  deposit: number;
  totalDeposit?: number;
  apy?: number;
}
const VaultPortfolioCompositionRow: React.FC<
  VaultPortfolioCompositionRowProps
> = ({ protocol, token1Name, token2Name, deposit, apy, totalDeposit }) => {
  const [normalizedProtocol, setNormalizedProtocol] = useState("");
  const [token1, setToken1] = useState<any>();
  const [token2, setToken2] = useState<any>();
  const rounded = ((((deposit || 0) / (totalDeposit || 1))) * 100).toFixed(1)
  

  
  useEffect(() => {
    let p = getProtocolAsset(protocol.toLowerCase());
    setNormalizedProtocol(p);
    setToken1(getTokenAsset(token1Name.toLowerCase()));
    setToken2(getTokenAsset(token2Name.toLowerCase()));
  }, [protocol]);

  return (
    <tr>
      <td>
        <div className="d-inline-block px-2">
          <Tooltip title={token1Name}>
            <img width={20} src={token1} alt="" />
          </Tooltip>
          <div>
            <small>{token1Name}</small>
          </div>
        </div>
        <div className="d-inline-block">/</div>
        <div className="d-inline-block px-2">
          <Tooltip title={token2Name}>
            <img width={20} src={token2} alt="" />
          </Tooltip>
          <div>
            <small>{token2Name}</small>
          </div>
        </div>
      </td>
      <td>{formatAmountUSD((Math.floor(deposit)) || 0)}</td>
      <td>{
      rounded
      
      } %</td>
      <td>{formatPercentage(apy)}</td>
      <td>3X</td>
      <td>
        <div>
          <img
            src={HomoraIcon}
            width={20}
            className="d-inline-block mr-1"
            alt=""
          />
        </div>
        <div>
          <small className="">
            {/* <img src={LendingSourceSample} alt="" /> */}
            Alpha Homora
          </small>
        </div>
      </td>
      <td>
        <div>
          {
            //@ts-ignore
            protocolAssets[normalizedProtocol]
          }
        </div>
        <div>
          <small>{normalizedProtocol}</small>{" "}
        </div>
      </td>
    </tr>
  );
};

export default VaultPortfolioCompositionRow;
