import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Frame, TargetAndTransition } from "framer";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";
import { BigNumber } from "ethers";

import colors from "shared/lib/designSystem/colors";
import theme from "shared/lib/designSystem/theme";
import {
  BaseInput,
  BaseInputButton,
  BaseInputContainer,
  BaseInputLabel,
  SecondaryText,
  Title,
} from "shared/lib/designSystem";
import {
  ACTIONS,
  V2WithdrawOption,
  V2WithdrawOptionList,
} from "../Modal/types";
import useVaultActionForm from "../../../../hooks/useVaultActionForm";
import {
  getAssets,
  isSolanaVault,
  VaultOptions,
} from "shared/lib/constants/constants";
import {
  ActionButton,
  ConnectWalletButton,
} from "shared/lib/components/Common/buttons";
import useConnectWalletModal from "shared/lib/hooks/useConnectWalletModal";
import { VaultInputValidationErrorList, VaultValidationErrors } from "../types";
import { getVaultColor } from "shared/lib/utils/vault";
import { getAssetDecimals, getAssetDisplay } from "shared/lib/utils/asset";
import { formatBigNumber } from "shared/lib/utils/math";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";
import HelpInfo from "shared/lib/components/Common/HelpInfo";
import AssetCircleContainer from "shared/lib/components/Common/AssetCircleContainer";
import { useTranslation } from "react-i18next";

const WithdrawTypeSegmentControlContainer = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: ${theme.border.radiusBig};
  background: ${colors.primaryText}0A;
  position: relative;
`;

const WithdrawTypeSegmentControl = styled.div`
  display: flex;
  padding: 10px 0;
  justify-content: center;
  flex: 1;
`;

const WithdrawTypeSegmentControlText = styled(Title)<{
  active: boolean;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.active ? colors.green : colors.text)};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const WithdrawTypeSegmentControlBackground = styled(Frame)`
  border: ${theme.border.width} ${theme.border.style} ${colors.green};
  border-radius: ${theme.border.radiusBig} !important;
  background-color: ${colors.green}0A !important;
`;

const InfoData = styled(Title)`
  font-size: 14px;
  line-height: 24px;
  margin-left: auto;
`;

const FormFooterButton = styled.div<{ color: string }>`
  background: ${(props) => props.color}14;
  border-radius: 100px;
  padding: 10px 16px;
`;

const WithdrawButtonLogo = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: ${(props) => props.color};
`;

const AllTokenStakedMessage = styled.div`
  padding-top: 16px;
  text-align: center;
`;

interface VaultV2WithdrawFormProps {
  vaultOption: VaultOptions;
  error?: VaultValidationErrors;
  onFormSubmit: () => void;
  depositBalanceInAsset: BigNumber;
  lockedBalanceInAsset: BigNumber;
  initiatedWithdrawAmount: BigNumber;
  canCompleteWithdraw: boolean;
  pricePerShare: BigNumber;
}

const VaultV2WithdrawForm: React.FC<VaultV2WithdrawFormProps> = ({
  vaultOption,
  error,
  onFormSubmit,
  depositBalanceInAsset,
  lockedBalanceInAsset,
  initiatedWithdrawAmount,
  canCompleteWithdraw,
  pricePerShare
}) => {
  const { t } = useTranslation();
  const asset = getAssets(vaultOption);
  const assetDisplay = getAssetDisplay(asset);
  const color = getVaultColor(vaultOption);
  const withdrawOptionRefs = useMemo(
    () =>
      V2WithdrawOptionList.reduce<any>((acc, curr) => {
        acc[curr] = React.createRef();
        return acc;
      }, {}),
    []
  );

  const {
    handleActionTypeChange,
    handleInputChange,
    handleMaxClick,
    vaultActionForm,
    withdrawMetadata,
  } = useVaultActionForm(vaultOption);
  const { active } = useWeb3Wallet();
  const [, setShowConnectModal] = useConnectWalletModal();

  const [activeBackgroundState, setActiveBackgroundState] =
    useState<TargetAndTransition>();
  const isInputNonZero = parseFloat(vaultActionForm.inputAmount) > 0;

  useEffect(() => {
    const currentRef =
      withdrawOptionRefs[
        vaultActionForm.withdrawOption! === "complete"
          ? "standard"
          : vaultActionForm.withdrawOption!
      ]?.current;

    if (!currentRef) {
      return;
    }

    const handleResize = () => {
      setActiveBackgroundState({
        left: currentRef.offsetLeft,
        top: currentRef.offsetTop,
        height: currentRef.clientHeight,
        width: currentRef.clientWidth,
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vaultActionForm.withdrawOption, withdrawOptionRefs]);

  /**
   * Check to make sure user only use complete when they can complete withdraw
   */
  useEffect(() => {
    if (!canCompleteWithdraw && vaultActionForm.withdrawOption === "complete") {
      handleActionTypeChange(ACTIONS.withdraw, "v2", {
        withdrawOption: "standard",
      });
    }
  }, [
    canCompleteWithdraw,
    handleActionTypeChange,
    vaultActionForm.withdrawOption,
  ]);

  const renderWithdrawOptionExplanation = useCallback(
    (withdrawOption: V2WithdrawOption, active: boolean) => {
      switch (withdrawOption) {
        case "instant":
          return (
            <TooltipExplanation
              title="INSTANT WITHDRAWAL"
              explanation={
                /* vaultOption === "rSOL-THETA"
                  ? "Instant withdrawals are currently disabled on the SOL vault. However, it is currently in the process of being implemented by the Zeta team and will be available in the near future."
                  :  */"Instant withdrawals are for funds that have been deposited but not yet deployed in the vault’s weekly strategy. Because these funds haven’t been deployed they can be withdrawn immediately."
              }
              renderContent={({ ref, ...triggerHandler }) => (
                <HelpInfo
                  containerRef={ref}
                  {...triggerHandler}
                  color={active ? colors.green : colors.text}
                >
                  i
                </HelpInfo>
              )}
            />
          );
        case "standard":
        case "complete":
          return (
            <TooltipExplanation
              title="STANDARD WITHDRAWAL"
              explanation={
                <>
                  Standard withdrawals are for funds that have been deployed in
                  the vault's weekly strategy and involve a 2-step withdrawal
                  process.
                  <br />
                  <br />
                  Step 1: Users need to remove their funds from the vault's pool
                  of investable capital by initiating a withdrawal.
                  <br />
                  <br />
                  Step 2: Initiated withdrawals are removed from the vault's
                  pool of investable capital every Friday at 12pm UTC and once
                  this happens users can complete their withdrawals and remove
                  their funds from the vault.
                </>
              }
              renderContent={({ ref, ...triggerHandler }) => (
                <HelpInfo
                  containerRef={ref}
                  {...triggerHandler}
                  color={active ? colors.green : colors.text}
                >
                  i
                </HelpInfo>
              )}
            />
          );
      }
    },
    [vaultOption]
  );

  const renderErrorText = useCallback((_error: VaultValidationErrors) => {
    if (VaultInputValidationErrorList.includes(_error)) {
      switch (_error) {
        case "withdrawLimitExceeded":
          return "Available limit exceeded";
        case "existingWithdraw":
          return "Existing withdraw from previous round";
      }
    }

    return "";
  }, []);

  const formExtraInfo = useMemo(() => {
    const decimals = getAssetDecimals(asset);
    switch (vaultActionForm.withdrawOption) {
      case "instant":
        return (
          <div className="d-flex align-items-center mt-3 mb-1">
            <SecondaryText>Instant withdraw limit</SecondaryText>
            <TooltipExplanation
              title="INSTANT WITHDRAW LIMIT"
              explanation="This is equal to the value of your funds that are currently not invested in the vault’s weekly strategy. These funds can withdrawn from the vault immediately."
              renderContent={({ ref, ...triggerHandler }) => (
                <HelpInfo containerRef={ref} {...triggerHandler}>
                  i
                </HelpInfo>
              )}
            />
            <InfoData
              color={error === "withdrawLimitExceeded" ? colors.red : undefined}
            >
              {formatBigNumber(depositBalanceInAsset, decimals)} {assetDisplay}
            </InfoData>
          </div>
        );
      case "standard":
      case "complete":
        return (
          <>
            <div className="d-flex align-items-center mt-3">
              <SecondaryText>Available Shares</SecondaryText>
              <TooltipExplanation
                title="AVAILABLE SHARES"
                explanation="This is the amount of shares you have in the vault available for withdrawal."
                renderContent={({ ref, ...triggerHandler }) => (
                  <HelpInfo containerRef={ref} {...triggerHandler}>
                    i
                  </HelpInfo>
                )}
              />
              <InfoData
                color={
                  error === "withdrawLimitExceeded" ? colors.red : undefined
                }
              >
                {formatBigNumber(lockedBalanceInAsset, decimals, 3)} SHARES
              </InfoData>
            </div>
            <div className="d-flex align-items-center mt-3 mb-1">
              <SecondaryText>Balance in USDC</SecondaryText>
              <TooltipExplanation
                title="BALANCE IN USDC"
                explanation={
                  <>
                    This is the equivalent amount of {assetDisplay} that you have currently in the vault.
                    <br />
                    <br />
                     You get the {assetDisplay} value by multiplying the amount of shares by price per share.                    
                    <br />
                    <br />                    
                  </>
                }
                renderContent={({ ref, ...triggerHandler }) => (
                  <HelpInfo containerRef={ref} {...triggerHandler}>
                    i
                  </HelpInfo>
                )}
              />
              <InfoData>
                {formatBigNumber(lockedBalanceInAsset.mul(pricePerShare), 24, 3)}{" "}
                {assetDisplay}
              </InfoData>
            </div>
            <div className="d-flex align-items-center mt-3 mb-1">
              <SecondaryText>Price per Share</SecondaryText>
              <TooltipExplanation
                title="PRICE PER SHARE IN USDC"
                explanation={
                  <>
                    This is the price per share expressed in {assetDisplay} that the vault currently has.
                    <br />                    
                    <br />                    
                  </>
                }
                renderContent={({ ref, ...triggerHandler }) => (
                  <HelpInfo containerRef={ref} {...triggerHandler}>
                    i
                  </HelpInfo>
                )}
              />
              <InfoData>
                {formatBigNumber(pricePerShare, 18, 3)}{" "}
                {assetDisplay}
              </InfoData>
            </div>
          </>
        );
    }

    return <></>;
  }, [
    vaultOption,
    asset,
    assetDisplay,
    depositBalanceInAsset,
    error,
    initiatedWithdrawAmount,
    lockedBalanceInAsset,
    pricePerShare,
    vaultActionForm.withdrawOption,
  ]);

  const renderButton = useCallback(() => {    
    if (active) {
      return (
        <ActionButton
          disabled={Boolean(error) || !isInputNonZero}
          onClick={() => {
            onFormSubmit();
          }}
          className="mt-4 py-3 mb-0"
          color={color}
        >
          {vaultActionForm.withdrawOption! === "instant"
            ? `Withdraw ${assetDisplay}`
            : "Initiate Withdrawal"}
        </ActionButton>
      );
    }

    return (
      <ConnectWalletButton
        onClick={() => setShowConnectModal(true)}
        type="button"
        className="mt-4 btn py-3 mb-0"
      >
        Connect Wallet
      </ConnectWalletButton>
    );
  }, [
    active,
    assetDisplay,
    color,
    error,
    isInputNonZero,
    onFormSubmit,
    setShowConnectModal,
    vaultActionForm.withdrawOption,
  ]);

  const formFooter = useMemo(() => {
    if (canCompleteWithdraw) {
      return (
        <FormFooterButton
          className="d-flex align-items-center justify-content-center mt-4 mx-3"
          role="button"
          color={color}
          onClick={() => {
            handleActionTypeChange(ACTIONS.withdraw, "v2", {
              withdrawOption: "complete",
            });
            handleMaxClick();
            onFormSubmit();
          }}
        >
          <AssetCircleContainer size={24} color={color}>
            <WithdrawButtonLogo color={color} />
          </AssetCircleContainer>
          <SecondaryText className="ml-1" color={colors.primaryText}>
            Complete your withdrawals
          </SecondaryText>
        </FormFooterButton>
      );
    } else if (withdrawMetadata.allTokensStaked) {
      return (
        <AllTokenStakedMessage>
          <SecondaryText color={colors.red}>
            {t("webapp:Withdrawals:AllrTokensStakedError", {
              rToken: vaultOption,
            })}
          </SecondaryText>
        </AllTokenStakedMessage>
      );
    }
    return null;
  }, [
    canCompleteWithdraw,
    color,
    handleActionTypeChange,
    handleMaxClick,
    onFormSubmit,
    withdrawMetadata,
    vaultOption,
    t,
  ]);

  return (
    <div style={{ position: "relative" }}>
      {/* Segment Control */}
      {/* <WithdrawTypeSegmentControlContainer>
        <WithdrawTypeSegmentControlBackground
          transition={{
            type: "keyframes",
            ease: "easeOut",
          }}
          initial={{
            height: "100%",
          }}
          animate={activeBackgroundState}
        />
        {V2WithdrawOptionList.map((withdrawOption) => {
          
          const active =
            vaultActionForm.withdrawOption === withdrawOption ||
            (withdrawOption === "standard" &&
              vaultActionForm.withdrawOption === "complete");
          return (
            <WithdrawTypeSegmentControl
              key={withdrawOption}
              ref={withdrawOptionRefs[withdrawOption]}
              role="button"
              onClick={() => {
                handleActionTypeChange(ACTIONS.withdraw, "v2", {
                  withdrawOption: withdrawOption,
                });
              }}
            >
              <WithdrawTypeSegmentControlText
                active={active}
                disabled={
                  vaultOption === "rSOL-THETA" && withdrawOption !== "standard"
                    ? true
                    : withdrawOption !== "instant" &&
                      !withdrawMetadata.allowStandardWithdraw
                }
              >
                {withdrawOption}{" "}
                {renderWithdrawOptionExplanation(withdrawOption, active)}
              </WithdrawTypeSegmentControlText>
            </WithdrawTypeSegmentControl>
          );
        })}
      </WithdrawTypeSegmentControlContainer> */}

      {/* Input */}

      <BaseInputContainer
        className="mb-2 bg-none "
        error={error ? VaultInputValidationErrorList.includes(error) : false}
      >
        <BaseInput
          type="number"
          inputWidth="100%"
          className="form-control depositInput"
          aria-label="ETH"
          placeholder="0"
          value={vaultActionForm.inputAmount}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
        
        {active && (
          <BaseInputButton className="maxBtnBlue" onClick={handleMaxClick}>
            MAX
          </BaseInputButton>
        )}
      </BaseInputContainer>
      {error && (
        <SecondaryText color={colors.red}>
          {renderErrorText(error)}
        </SecondaryText>
      )}
      {formExtraInfo}
      {renderButton()}
      
      {formFooter}
    </div>
  );
};

export default VaultV2WithdrawForm;
