import moment from "moment";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import { fetchUserPortfolioValue } from "../../hooks/useFetchBlueSwanData";
import TimeChart from "../Chart/TimeChart";

const options = {
  plugins: {
    /*  datalabels: {
          display: true,
          color: "black",
          formatter: Math.round,
          anchor: "end",
          offset: -20,
          align: "start"
        } */
  },
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function(tooltipItem:any, data:any) {
        return data['labels'][tooltipItem['index']] + ': $' + data['datasets'][0]['data'][tooltipItem['index']] ;
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#BACFFF",
          fontSize: 14,
          padding: 0,
          callback: function(value:any) {
            if(parseInt(value) >= 1000){
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + value;
            }
          }    
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: "#BACFFF",
          fontSize: 14,
          padding: 0,
        },
      },
    ],
  },
};

const labels = [
  "Jul 31",
  "Aug 07",
  "Aug 14",
  "Aug 21",
  "Aug 28",
  "Sep 04",
  "Sep 11",
  "Sep 18",
  "Sep 25",
  "Oct 02",
  "Oct 09",
  "Oct 16",
];

const max = 80;
const min = 0;
/* const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => Math.floor(Math.random() * (max - min + 1) + min)),
      borderColor: "rgba(189, 247, 255, 1)",
      backgroundColor: "rgba(189, 247, 255, 0)",
    },
  ],
}; */
interface PortfolioValueProps {
  labels: any;
  dataSource: any;
}
const PortfolioValue: React.FC<PortfolioValueProps> = ({
  labels,
  dataSource,
}) => {
  const data = {
    labels,
    datasets: [
      {
        data: dataSource,
        borderColor: "rgba(189, 247, 255, 1)",
        backgroundColor: "rgba(189, 247, 255, 0)",
      },
    ],
  };
  return (
    <div
      style={{
        position: "relative",
        height: "240px",
        width: "100%",
      }}
    >
      <Line options={options} data={data} />
    </div>
  );
};

export default PortfolioValue;
