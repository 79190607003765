import React from "react";
import HelpInfo from "shared/lib/components/Common/HelpInfo";
import TooltipExplanation from "shared/lib/components/Common/TooltipExplanation";

interface RowData {
  title: string;
  helpText?: string;
  mainData: string;
}

interface OverviewBoxRowData {
  data: RowData[];
}

const OverviewBoxRow: React.FC<OverviewBoxRowData> = (
  props: OverviewBoxRowData
) => {
  return (
    <>
      <div className="row no-gutters box radius bg box-items py-4 my-3">
        {props.data.map((row) => {
          return (
            <>
              <div className="col-md-4 px-3 box-item">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 box-heading text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <span>{row.title}</span>

                          <TooltipExplanation
                            title={row.title}
                            explanation={row.helpText}
                            renderContent={({ ref, ...triggerHandler }) => (
                              <HelpInfo containerRef={ref} {...triggerHandler}>
                                i
                              </HelpInfo>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 py-3 text-center">
                        {row.mainData}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}

        {/* <div className="col-md-4 p-3 box-item">
          <div className="row">
            <div className="col-12 box-heading">Blockchains</div>
            <div className="col-12 py-3 text-center">
              <img src={Optimism} className="mr-1" width="30" alt="" />
              <img src={Optimism} className="mr-1" width="30" alt="" />
              <img src={Optimism} className="mr-1" width="30" alt="" />
            </div>
          </div>
        </div>

        <div className="col-md-4 p-3 box-item">
          <div className="row">
            <div className="col-12 box-heading">Asset Exposure</div>
            <div className="col-12 py-3 text-center">
              <h4 className="text-uppercase">US Dollar</h4>
            </div>
          </div>
        </div>

        <div className="col-md-4 p-3 box-item">
          <div className="row">
            <div className="col-12 box-heading">Net APY Range</div>
            <div className="col-12 py-3 text-center">
              <h4 className="text-uppercase">
                0%
                <small>to</small>
                27.4%
              </h4>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default OverviewBoxRow;
