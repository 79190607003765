import styled from "styled-components";

const FieldLoader = () => {
  const FieldLoaderContainer = styled.div`
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 10px auto;

    &:after {
      content: " ";
      display: block;
      width: 30px;
      height: 30px;
      margin: 2px;
      border-radius: 50%;
      border: 6px solid #3e73c4;
      border-color: #3e73c4 transparent #3e73c4 transparent;
      animation: loading-animation 1.2s linear infinite;
    }
    @keyframes loading-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
  return <FieldLoaderContainer></FieldLoaderContainer>;
};

export default FieldLoader;
