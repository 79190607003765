import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import {
  VaultName,
  VaultNameOptionMap,
  VaultOptions,
  VaultVersion,
  getBlueSwanVaultByChain
} from "shared/lib/constants/constants";

const useVaultOption = () => {
  const matchv1 = useRouteMatch<{ vaultSymbol: string }>(
    "/theta-vault/:vaultSymbol"
  );
  const matchv2 = useRouteMatch<{ vaultSymbol: string }>(
    "/v2/theta-vault/:vaultSymbol"
  );
  const matchblueswan = useRouteMatch<{ vaultSymbol: string }>(
    "/vault/:vault/"
  );


  const [vaultOption, vaultVersion] = useMemo((): [
    VaultOptions | undefined,
    VaultVersion
  ] => {
    
    if (
      matchblueswan?.params.vaultSymbol &&
      matchblueswan.params.vaultSymbol in VaultNameOptionMap
    ) {
      return [
        VaultNameOptionMap[getBlueSwanVaultByChain(matchblueswan?.params.vaultSymbol) as VaultName],
        "v2",
      ];
    }

    
    /** Default value */
    return [undefined, "v2"];
  }, [matchblueswan]);

  return { vaultOption, vaultVersion };
};

export default useVaultOption;
