import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NoWalletConnectedIcon from "../../assets/icons/blueswan/no-wallet-connected-icon.svg";
import NoPositionsIcon from "../../assets/icons/blueswan/no-positions.svg";
import { Title } from "shared/lib/designSystem";
import PortfolioValue from "../../components/Portfolio/PortfolioValue";
import PortfolioAllocation from "../../components/Portfolio/PortfolioAllocation";
import PortfolioActivity from "../../components/Portfolio/PortfolioActivity";
import { formatAmountUSD } from "shared/lib/utils/math";
import DepositModal from "../../components/Deposit/DepositModal";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import useConnectWalletModal from "shared/lib/hooks/useConnectWalletModal";
import {
  fetchStrategyData,
  fetchUserPortfolioValue,
} from "../../hooks/useFetchBlueSwanData";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import VaultPortfolioRow from "../../components/Vault/VaultPortfolioRow";

import { UserVaultEvent } from "shared/lib/models/vault";
import { formatPercentage } from "../../constants/constants";
import { BlueSwanVaultData } from "../../store/types";

export type VaultDataFromRow = {
  positionValue: number;
  positionValue1dAgo: number;
  positionValue7dAgo: number;
  apy: number;
  apy1dAgo: number;
  apy7dAgo: number;
  block: number;
  block1dAgo: number;
  block7dAgo: number;
  transactions: UserVaultEvent[];
  balance: number;
  roi: number;
  isLoaded: boolean;
};

const vaultDataFromRowDefault: VaultDataFromRow = {
  positionValue: 0,
  positionValue1dAgo: 0,
  positionValue7dAgo: 0,
  apy: 0,
  apy1dAgo: 0,
  apy7dAgo: 0,
  block: 0,
  block1dAgo: 0,
  block7dAgo: 0,
  transactions: [],
  balance: 0,
  roi: 0,
  isLoaded: false,
};

const PerformanceTitle = styled(Title)`
  font-size: 18px;
  margin-top: 48px;
`;

const NoWalletConnectedContainer = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 20px;
  display: inline-block;
  width: 100%;
`;

const ConnectWalletButton = styled.button`
  border: 0;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  background: rgba(95, 133, 219, 0.32);
  border-radius: 4px;
  color: #bacfff;
  padding: 5px 10px;
`;

const NoWalletConnected = () => {
  const [, setShowConnectWalletModal] = useConnectWalletModal();
  return (
    <NoWalletConnectedContainer>
      <div className="row py-5">
        <div className="col-12 text-center">
          <img src={NoWalletConnectedIcon} alt="" />
        </div>
        <div className="col-12 py-3">
          <h6 className="text-center">
            Connect your wallet to visualize charts!
          </h6>
        </div>

        <div className="col-md-12 text-center">
          <ConnectWalletButton
            onClick={() => {
              setShowConnectWalletModal(true);
            }}
          >
            Connect Wallet
          </ConnectWalletButton>
        </div>
      </div>
    </NoWalletConnectedContainer>
  );
};

const LoadingContainer = () => {
  return (
    <NoWalletConnectedContainer>
      <div className="row py-5">
        <div className="col-12 py-3">
          <h6 className="text-center">Loading ...</h6>
        </div>
      </div>
    </NoWalletConnectedContainer>
  );
};

const PortfolioPage = () => {
  const [portfolioScreen, setPortfolioScreen] = useState(1);
  const [showDepositModal, setShowDepositModal] = useState(false);

  const [selectedVault, setSelectedVault] = useState<string>("");
  const [vaultNetwork, setSelectedVaultNetwork] = useState<string>("");
  const onDeposit = () => {};
  const { active } = useWeb3Wallet();
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("green-gradient");

    return () => {
      // 👇️ removing classes from body element
      // when the component unmounts
      document.body.classList.remove("green-gradient");
    };
  }, []);

  const [error, setError] = useState(false);
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [chartLabels, setChartLabels] = useState([]);
  const [chartSource, setChartSource] = useState([]);
  const { account } = useWeb3Wallet();

  const [totalValue, setTotalValue] = useState(0);
  const [totalRoi, setTotalRoi] = useState(0);
  const [performanceChange1d, setPerformanceChange1d] = useState(0);
  const [performanceChange7d, setPerformanceChange7d] = useState(0);
  const [apy1dChange, setApy1dChange] = useState(0);
  const [apy7dChange, setApy7dChange] = useState(0);

  const [avaxPositionData, setAvaxPositionData] = useState<VaultDataFromRow>(
    vaultDataFromRowDefault
  );
  const [ftmPositionData, setFtmPositionData] = useState<VaultDataFromRow>(
    vaultDataFromRowDefault
  );
  const [opPositionData, setOpPositionData] = useState<VaultDataFromRow>(
    vaultDataFromRowDefault
  );

  const vaultsData = [avaxPositionData, ftmPositionData, opPositionData];
  const checkTransactions = () => {
    return (
      avaxPositionData.transactions.length > 0 ||
      ftmPositionData.transactions.length > 0 ||
      opPositionData.transactions.length > 0
    );
  };

  const checkVaultsLoaded = () => {
    return (
      avaxPositionData.isLoaded &&
      avaxPositionData.isLoaded &&
      opPositionData.isLoaded
    );
  };
  const activityData = avaxPositionData.transactions
    .map((transaction) => {
      return { chain: "avalanche", data: transaction };
    })
    .concat(
      ftmPositionData.transactions
        .map((transaction) => {
          return { chain: "fantom", data: transaction };
        })
        .concat(
          opPositionData.transactions.map((transaction) => {
            return { chain: "optimism", data: transaction };
          })
        )
    )
    .sort((a, b) => a.data.block - b.data.block);

  const calculateReturnsAtBlock = (
    transactions: UserVaultEvent[],
    block: number,
    positionValue: number
  ) => {
    const deposited = transactions.reduce(
      (acc, curr) =>
        curr.eventType === "deposit" && curr.block < block
          ? acc + curr.usdValue
          : acc,
      0
    );
    const withdrawn = transactions.reduce(
      (acc, curr) =>
        curr.eventType === "withdrawal" && curr.block < block
          ? acc + curr.usdValue
          : acc,
      0
    );
    return positionValue + withdrawn - deposited;
  };

  const calculateReturns = (positionData: VaultDataFromRow) => {
    const returnNow = calculateReturnsAtBlock(
      positionData.transactions,
      positionData.block,
      positionData.balance
    );
    const return1dAgo = calculateReturnsAtBlock(
      positionData.transactions,
      positionData.block1dAgo,
      positionData.balance
    );
    const return7dAgo = calculateReturnsAtBlock(
      positionData.transactions,
      positionData.block7dAgo,
      positionData.balance
    );
    return { returnNow, return1dAgo, return7dAgo };
  };

  const calculateReturnsForVaults = (vaultDatas: VaultDataFromRow[]) => {
    let returnsNow = 0;
    let returns1dAgo = 0;
    let returns7dAgo = 0;
    for (const vaultData of vaultDatas) {
      const { returnNow, return1dAgo, return7dAgo } =
        calculateReturns(vaultData);
      returnsNow += returnNow;
      returns1dAgo += return1dAgo;
      returns7dAgo += return7dAgo;
    }
    return { returnsNow, returns1dAgo, returns7dAgo };
  };

  const calculateWeightedApy = (apys: number[], deposited: number[]) => {
    const totalDeposited = deposited.reduce((acc, curr) => acc + curr, 0);
    let apy = 0;
    for (let i = 0; i < apys.length; i++) {
      apy += (deposited[i] * apys[i]) / totalDeposited || 0;
    }
    return apy;
  };

  const calculateApys = (vaultData: VaultDataFromRow[]) => {
    const valuesNow = vaultData.map((vault) => vault.positionValue);
    const values1dAgo = vaultData.map((vault) => vault.positionValue1dAgo);
    const values7dAgo = vaultData.map((vault) => vault.positionValue7dAgo);
    const apysNow = vaultData.map((vault) => vault.apy);
    const apys1dAgo = vaultData.map((vault) => vault.apy1dAgo);
    const apys7dAgo = vaultData.map((vault) => vault.apy7dAgo);
    const apyNow = calculateWeightedApy(apysNow, valuesNow);
    const apy1dAgo = calculateWeightedApy(apys1dAgo, values1dAgo);
    const apy7dAgo = calculateWeightedApy(apys7dAgo, values7dAgo);
    return { apyNow, apy1dAgo, apy7dAgo };
  };

  const calculateTotalDeposited = (vaultData: VaultDataFromRow[]) => {
    let deposited = 0;
    vaultData.map((vault) => {
      deposited += vault.transactions.reduce(
        (acc, curr) =>
          curr.eventType === "deposit" ? acc + curr.usdValue : acc,
        0
      );
    });
    return deposited;
  };
  const [userBalance, setUserBalance] = useState(0);
  useEffect(() => {
    const totalDeposited = calculateTotalDeposited(vaultsData);
    const positionValue = vaultsData.reduce(
      (acc, curr) => acc + curr.positionValue,
      0
    );
    const bal = vaultsData.reduce((acc, curr) => acc + curr.balance, 0);
    setUserBalance(bal);

    const wRoi = vaultsData.reduce(
      (acc, curr) => acc + curr.balance * curr.roi,
      0
    );

    if (
      avaxPositionData.isLoaded &&
      ftmPositionData.isLoaded &&
      opPositionData.isLoaded
    ) {
      const { returnsNow, returns1dAgo, returns7dAgo } =
        calculateReturnsForVaults(vaultsData);
      // const roi = (100 * returnsNow) / totalDeposited || 0;
      const performance1d = returnsNow - returns1dAgo;
      const performance7d = returnsNow - returns7dAgo;
      const { apyNow, apy1dAgo, apy7dAgo } = calculateApys(vaultsData);
      const apy1d = apyNow - apy1dAgo;
      const apy7d = apyNow - apy7dAgo;
      setTotalValue(positionValue);

      setTotalRoi(wRoi / bal || 0);
      setPerformanceChange1d(performance1d);
      setPerformanceChange7d(performance7d);
      setApy1dChange(apy1d);
      setApy7dChange(apy7d);
    }
  }, vaultsData);

  useEffect(() => {
    async function fetch() {
      if (account) {
        const { loading, error, data } = await fetchUserPortfolioValue(account);
        if (!error && data) {
          if (data.value?.result) {
            let labels: any = [];
            let source: any = [];
            let foundValue = false;
            data.value.result.map((item: any, idx: number) => {
              let d = new Date(item.timeStampISO);
              let fDate = moment(d).format("MMM D");

              if (!foundValue && idx < data.value.result.length) {
                foundValue = item.netWorthUSD > 0;
              }

              if (foundValue) {
                labels.push(fDate);
                source.push(parseInt(`${Number(item.netWorthUSD)}`));
              }
            });
            setChartLabels(labels);
            setChartSource(source);
          }
        }
        if (error) {
          setError(error);
          console.log("Vault Apy Data error");
        }
      }
    }
    // if (checkTransactions()) fetch();
    fetch();
  }, [avaxPositionData, ftmPositionData, opPositionData]);

  const getScreenLoaded = (screen: any) => {
    return checkVaultsLoaded() ? screen : <LoadingContainer />;
  };

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchStrategyData();
      if (!error && data) {
        setVData(data);
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }
    if (!vdata) {
      fetch();
    }
  }, [vdata]);

  return (
    <>
      <DepositModal
        vaultFetchedData={vdata}
        network={vaultNetwork}
        selectedVault={selectedVault}
        show={showDepositModal}
        onClose={() => {
          setShowDepositModal(false);
        }}
      />

      <div className="container py-5">
        {/* Heading */}
        <div className="row section-heading pb-3">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div className="tag">VAULTS / Analytics</div>
              </div>
            </div>
            <div className="row py-3">
              <div className="col-12">
                <h1 className="heading">Your Portfolio</h1>
              </div>
            </div>
          </div>
        </div>
        {/* Heading */}

        <div className="row box bg radius py-3 box-items px-2 mb-3">
          <div className="col-12">
            <div className="row mb-3">
              <div className="col-12">
                <h5 className="text-uppercase d-inline font-weight-600 mr-3">
                  Analytics
                </h5>
              </div>
            </div>
            {!active ? (
              <>
                <div className="row portfolio-analytics">
                  <div className="col-md-3 item-col">
                    <div className="row">
                      <div className="col-12">
                        <h6>Portfolio Value</h6>
                      </div>
                      <div className="col-11 mx-auto py-3 item-content">
                        <h4 className="text-center">-</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 item-col">
                    <div className="row">
                      <div className="col-12">
                        <h6>Total Amount Deposited </h6>
                      </div>
                      <div className="col-11 mx-auto py-3 item-content">
                        <h4 className="text-center">-</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 item-col">
                    <div className="row">
                      <div className="col-12">
                        <h6>Return on Investment (ROI)</h6>
                      </div>
                      <div className="col-11 mx-auto py-3 item-content">
                        <h4 className="text-center">-</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 item-col">
                    <div className="row">
                      <div className="col-12">
                        <h6>Portfolio performance</h6>
                      </div>
                      <div className="col-11 mx-auto pt-2 item-content">
                        <div className="row">
                          <div className="col-6">
                            <h6>Last 24 hours</h6>
                          </div>
                          <div className="col-6">
                            <h6 className="text-right">-</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-11 mx-auto pt-2 mt-2 item-content">
                        <div className="row">
                          <div className="col-6">
                            <h6>Last 7 days</h6>
                          </div>
                          <div className="col-6">
                            <h6 className="text-right">-</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 item-col">
                    <div className="row">
                      <div className="col-12">
                        <h6>Weighted quoted net APY</h6>
                      </div>
                      <div className="col-11 mx-auto pt-2 item-content">
                        <div className="row">
                          <div className="col-6">
                            <h6>Last 24 hours</h6>
                          </div>
                          <div className="col-6">
                            <h6 className="text-right">-</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-11 mx-auto pt-2 mt-2 item-content">
                        <div className="row">
                          <div className="col-6">
                            <h6>Last 7 days</h6>
                          </div>
                          <div className="col-6">
                            <h6 className="text-right">-</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </>
            ) : (
              <>
                {checkVaultsLoaded() ? (
                  <div className="row portfolio-analytics">
                    <div className="col-md-3 item-col">
                      <div className="row">
                        <div className="col-12">
                          <h6>Portfolio Value</h6>
                        </div>
                        <div className="col-11 mx-auto py-3 item-content">
                          <h4 className="text-center">{`$ ${totalValue.toFixed(
                            1
                          )}`}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 item-col">
                      <div className="row">
                        <div className="col-12">
                          <h6>Total Amount Deposited </h6>
                        </div>
                        <div className="col-11 mx-auto py-3 item-content">
                          <h4 className="text-center">{`$ ${userBalance.toFixed(
                            1
                          )}`}</h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 item-col">
                      <div className="row">
                        <div className="col-12">
                          <h6>Return on Investment (ROI)</h6>
                        </div>
                        <div className="col-11 mx-auto py-3 item-content">
                          <h4 className="text-center">
                            {`${formatPercentage(totalRoi)}`}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 item-col">
                      <div className="row">
                        <div className="col-12">
                          <h6>Portfolio performance</h6>
                        </div>
                        <div className="col-11 mx-auto pt-2 item-content">
                          <div className="row">
                            <div className="col-6">
                              <h6>Last 24 hours</h6>
                            </div>
                            <div className="col-6">
                              <h6 className="text-right">
                                {`${formatPercentage(performanceChange1d)}`}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-11 mx-auto pt-2 mt-2 item-content">
                          <div className="row">
                            <div className="col-6">
                              <h6>Last 7 days</h6>
                            </div>
                            <div className="col-6">
                              <h6 className="text-right">{`${formatPercentage(
                                performanceChange7d
                              )}`}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <SkeletonTheme
                    baseColor="#bdf7ff0a"
                    highlightColor="#5f85db14"
                  >
                    <p>
                      <Skeleton className="my-2" height={20} />
                      <Skeleton className="my-2" height={40} />
                    </p>
                  </SkeletonTheme>
                )}
              </>
            )}
          </div>
        </div>

        {/* Vault Switcher */}
        <div className="row">
          <div className="col-12 text-center text-md-left">
            <div
              className={`bs-tab-btn ${
                portfolioScreen == 1 ? "active" : ""
              } my-2 pr-3`}
            >
              <button
                onClick={() => {
                  setPortfolioScreen(1);
                }}
              >
                Value
              </button>
              <div className="highlighted"></div>
            </div>
            <div
              className={`bs-tab-btn ${
                portfolioScreen == 2 ? "active" : ""
              } my-2 pr-3`}
            >
              <button
                onClick={() => {
                  setPortfolioScreen(2);
                }}
              >
                Portfolio allocation﻿
              </button>
              <div className="highlighted"></div>
            </div>
            <div
              className={`bs-tab-btn ${
                portfolioScreen == 3 ? "active" : ""
              } my-2 pr-3`}
            >
              <button
                onClick={() => {
                  setPortfolioScreen(3);
                }}
              >
                Activity
              </button>
              <div className="highlighted"></div>
            </div>
          </div>
        </div>
        <div className="line-separator fixed-margin"></div>
        {/* Vault Switcher */}

        <div className="row mt-4">
          <div className="col-12 py-3">
            {portfolioScreen === 1 &&
              (active ? (
                <>
                  {chartLabels.length > 0 ? (
                    <PortfolioValue
                      labels={chartLabels}
                      dataSource={chartSource}
                    />
                  ) : (
                    <LoadingContainer />
                  )}
                </>
              ) : (
                <NoWalletConnected />
              ))}
            {portfolioScreen === 2 &&
              (active ? (
                getScreenLoaded(<PortfolioAllocation />)
              ) : (
                <NoWalletConnected />
              ))}
            {portfolioScreen === 3 &&
              (active ? (
                getScreenLoaded(<PortfolioActivity userEvents={activityData} />)
              ) : (
                <NoWalletConnected />
              ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12 my-3">
            {/* Catalog Heading */}
            <div className="row">
              <div className="col-12 my-4">
                <h6 className="d-inline-block mr-3 text-uppercase">
                  My positions
                </h6>
                <div className="tag">All</div>
              </div>
            </div>
            {/* Catalog Heading */}
            {!active && (
              <NoWalletConnectedContainer>
                <div className="row py-5">
                  <div className="col-12 text-center">
                    <img src={NoPositionsIcon} alt="" />
                  </div>
                  <div className="col-12 pt-3">
                    <h6 className="text-center">No positions!</h6>
                  </div>
                </div>
              </NoWalletConnectedContainer>
            )}

            {active && (
              <>
                {/* {chartLabels.length > 0 ? ( */}
                <>
                  <VaultPortfolioRow
                    onDeposit={() => {
                      setSelectedVaultNetwork("avalanche");
                      setSelectedVault("S01AVAX");
                      setShowDepositModal(!showDepositModal);
                    }}
                    network="avalanche"
                    setParentData={setAvaxPositionData}
                  />
                  <VaultPortfolioRow
                    network="fantom"
                    onDeposit={() => {
                      setSelectedVaultNetwork("fantom");
                      setSelectedVault("S01FTM");
                      setShowDepositModal(!showDepositModal);
                    }}
                    setParentData={setFtmPositionData}
                  />
                  <VaultPortfolioRow
                    network="optimism"
                    setParentData={setOpPositionData}
                    onDeposit={() => {
                      setSelectedVaultNetwork("optimism");
                      setSelectedVault("S01OP");
                      setShowDepositModal(!showDepositModal);
                    }}
                  />
                </>
                {/* ) : (
                  <> </>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>

      {/* <Container>
        <Row className="justify-content-center">
          <Col sm="11" md="9" lg="7" className="d-flex flex-wrap">
            <PerformanceTitle>PORTFOLIO SUMMARY</PerformanceTitle>
            <PortfolioPerformance />
            <PortfolioPositions />
            <PortfolioTransactions />
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default PortfolioPage;
