import React from "react";
import styled from "styled-components";
import { GenericButton } from "../Common/Buttons";
import { Link } from "react-router-dom";

const OverviewButton = styled.button`
padding: 15px;
border: 0;
color: #bacfff;
text-transform: uppercase;
font-weight: 600;
background: rgba(95, 133, 219, 0.32);
border-radius: 4px;
display: inline-block;
width: 100%;
span{
  font-size:14px!important; 
}
&:hover{
  opacity: .8;
}
`
const MenuMainLabel = styled.div`
  background: none !important;
  border-radius: 4px;
  padding: 10px;
  color: #bdf7ff;
  text-align: center;
  margin: 10px auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
`;

const MenuSecondaryLabel = styled.div`
  background: rgba(189, 247, 255, 0.04);
  border-radius: 4px;
  padding: 10px;
  color: #bacfff;
  text-align: center;
  margin: 10px auto;
  font-size:14px;
  font-weight: 600;
  text-transform: uppercase;
  span {
    display: block;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
`;

const MenuButtonItem = styled(Link)`
  font-size: 14px;
  background: rgba(95, 133, 219, 0.08);
  border-radius: 4px;
  padding: 10px 20px;
  color: #bacfff;
  text-align: left;
  margin: 10px auto;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  width: 100%;
  display: inline-block;
  &:hover {
    text-decoration: none;
    opacity: 0.6;
    color: #bacfff;
  }
`;

const OverviewMenu: React.FC = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <Link to={"/analytics"}>
            <OverviewButton>
              <span className="d-inline-block mr-2">
                <svg
                  width="17"
                  height="10"
                  viewBox="0 0 17 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33317 10L0.166504 8.83333L6.33317 2.625L9.6665 5.95833L13.9998 1.66667H11.8332V0H16.8332V5H15.1665V2.83333L9.6665 8.33333L6.33317 5L1.33317 10Z"
                    fill="#BACFFF"
                  />
                </svg>
              </span>
              <span>Overview</span>
            </OverviewButton>
          </Link>
        </div>
        <div className="col-12">
          <MenuMainLabel>Select Available Vaults</MenuMainLabel>
        </div>
        <div className="col-12">
          <MenuSecondaryLabel>
            <span className="text-uppercase">Swan 01</span>
            <div>US$ PSEUDO DELTA NEUTRAL FARMING</div>
          </MenuSecondaryLabel>
        </div>
        <div className="col-12">
          <MenuButtonItem to={"/vault/avalanche/summary"}>
            01 / Avalanche
          </MenuButtonItem>
        </div>
        <div className="col-12">
          <MenuButtonItem to={"/vault/fantom/summary"}>
            01 / Fantom
          </MenuButtonItem>
        </div>
        <div className="col-12">
          <MenuButtonItem to={"/vault/optimism/summary"}>
            01 / Optimism
          </MenuButtonItem>
        </div>

        <div className="col-12">
          <MenuSecondaryLabel>
            <span>Swan 02 - Coming soon</span>
            <div>Long-Only Yield</div>
          </MenuSecondaryLabel>
        </div>
        <div className="col-12">
          <MenuButtonItem className="link-disabled" to={"#"}>
            02 / ETH Long Yield
          </MenuButtonItem>
        </div>
        <div className="col-12">
          <MenuButtonItem className="link-disabled" to={"#"}>
            02 / Polygon Long Yield
          </MenuButtonItem>
        </div>
        <div className="col-12">
          <MenuButtonItem className="link-disabled" to={"#"}>
            02 / Avax Long Yield
          </MenuButtonItem>
        </div>
      </div>
    </>
  );
};

export default OverviewMenu;
