import { BigNumber, Contract, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Chains } from "shared/lib/constants/constants";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import { useWeb3Context } from "shared/lib/hooks/web3Context";
import { switchChains } from "shared/lib/utils/chainSwitching";
import { formatVaultBalance } from "shared/lib/utils/math";
import { _abi } from "../../abis/blueswanVault";
import { useBlueSwanVaultContract } from "shared/lib/hooks/useVaultContract";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import { BlueSwanVaultAddressMap, LEVERAGE } from "../../constants/constants";
import { fetchStrategyData } from "../../hooks/useFetchBlueSwanData";
import useRedirectOnWrongChain from "../../hooks/useRedirectOnWrongChain";
import { BlueSwanVaultData } from "../../store/types";
// import fetchBlueSwanData from "../../hooks/useFetchBlueSwanData";
import { formatBigNumber } from "shared/lib/utils/math";
import {
  useV2VaultData,
  useAssetBalance,
} from "shared/lib/hooks/web3DataContext";
import CapacityBar from "../Common/CapacityBar";
import Tooltip from "../Tooltip";
import useVaultActionForm from "../../hooks/useVaultActionForm";
import useVaultStrategyData from "shared/lib/hooks/useVaultStrategyData";

interface VaultDetailRowProps {
  network: string;
  tags: string[];
  onDeposit: () => void;
}

const VaultDetailRow: React.FC<VaultDetailRowProps> = ({
  network,
  tags,
  onDeposit,
}) => {
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [error, setError] = useState(false);

  //@ts-ignore
  const chain = BlueSwanVaultAddressMap[network];

  const [vaultBalance, setVaultBalance] = useState(0);
  const [accountBalance, setAccountBalance] = useState(0);

  const {
    data: {
      asset,
      cap,
      decimals,
      depositBalanceInAsset,
      lockedBalanceInAsset,
      pricePerShare,
      round,
      totalBalance,
      withdrawals,
    },
    loading,
  } = useV2VaultData(chain.symbol);

  const { responses: strategyData, loading: loadingStrategies } =
    useVaultStrategyData(chain.symbol);

  const { handleActionTypeChange, vaultActionForm, handleMaxClick } =
    useVaultActionForm(chain.symbol);
  const { balance: userAssetBalance } = useAssetBalance(
    vaultActionForm.depositAsset || asset
  );
  useEffect(() => {
    setVaultBalance(formatVaultBalance(totalBalance));
    setAccountBalance(formatVaultBalance(userAssetBalance));
  }, [chain.symbol, network]);

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchStrategyData(network);
      if (!error && data) {
        setVData(data);
      }
      if (error) {
        setError(error);
      }
    }
    if (!vdata) {
      fetch();
    }
  }, [vdata]);

  const checkWallet = async () => {
    //@ts-ignore
    // if (active) await activate(connectedWallet, Chains.Ethereum);
  };
  return (
    <>
      {/* Vault Detail */}
      <div className="row">
        <div className="col-11 mx-auto col-md-12">
          <div className="row box bg radius py-3 box-items px-2 my-3">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="tag vault my-2">SWAN 01</div>
                  <div className="tag vault my-2">
                    US$ Pseudo Delta Neutral Farming
                  </div>
                </div>
                <div className="col-md-6 text-md-right">
                  <div className="tag investment mr-auto">
                  YOUR EQUITY VALUE:{" "}
                    <span>
                      $
                      {lockedBalanceInAsset
                        ? Number(
                            formatBigNumber(
                              lockedBalanceInAsset.mul(pricePerShare).div(1e12),
                              12
                            )
                          )
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-4 col-md-3 box-item">
                      <div className="row">
                        <div className="col-12 box-heading pb-3">Chain</div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <Tooltip title={network}>
                            <img
                              src={
                                network == "avalanche"
                                  ? Avalanche
                                  : network == "fantom"
                                  ? Fantom
                                  : Optimism
                              }
                              className="mr-1 d-inline-block"
                              width="20"
                              alt=""
                            />
                          </Tooltip>
                          <h6 className="text-uppercase d-inline">{network}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 col-md-2 text-center px-3 box-item">
                      <div className="row">
                        <div className="col-12 box-heading pb-3">Net APY</div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <h6 className="text-uppercase">
                            {vdata ? (Number(vdata.apy24) * 100).toFixed(2) : 0}{" "}
                            %
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-md-2 text-center px-3 box-item">
                      <div className="row">
                        <div className="col-12 box-heading pb-3"># Pools</div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <h6 className="text-uppercase">
                            {strategyData ? strategyData.length : 0}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-md-2 text-center px-3 box-item">
                      <div className="row">
                        <div className="col-12 box-heading pb-3">
                          Target Leverage
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          <h6 className="text-uppercase">3X</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 text-center px-3 box-item">
                      <div className="row">
                        <div className="col-12 box-heading pb-2">
                          TVL / Capacity
                        </div>

                        <div className="px-1 mx-auto">
                          {!loadingStrategies &&
                          strategyData &&
                          strategyData.length ? (
                            <CapacityBar
                              loading={false}
                              current={
                                lockedBalanceInAsset
                                  ? Number(formatBigNumber(totalBalance, 6)) * LEVERAGE
                                  : 0
                              }
                              cap={strategyData.length * 150000}
                              labelConfig={{
                                fontSize: 12,
                              }}
                              statsConfig={{
                                fontSize: 14,
                              }}
                              barConfig={{
                                height: 4,
                                extraClassNames: "my-2 mx-auto",
                                radius: 2,
                              }}
                            />
                          ) : (
                            <>Loading ... </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-center text-md-right pt-2">
                  <Link
                    to={`/vault/${network}/summary`}
                    className="bs-btn light-blue d-inline-block mr-3 px-4"
                  >
                    Learn More
                  </Link>
                  <button
                    onClick={() => {
                      checkWallet();
                      onDeposit();
                    }}
                    className="bs-btn blue d-inline-block px-4"
                  >
                    DEPOSIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Vault Detail */}
    </>
  );
};

export default VaultDetailRow;
