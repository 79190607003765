import React from "react";
import ProgressBar, {
  BarConfig,
} from "shared/lib/components/Deposit/ProgressBar";
import { SecondaryText, Title } from "shared/lib/designSystem";
import colors from "shared/lib/designSystem/colors";

import useLoadingText from "shared/lib/hooks/useLoadingText";
import { Assets } from "shared/lib/store/types";
import { getAssetDisplay } from "shared/lib/utils/asset";
import { formatAmount } from "shared/lib/utils/math";

const CapacityBar: React.FC<{
  loading: boolean;
  current: number;
  cap: number;
  copies?: {
    current: string;
    cap: string;
  };
  displayData?: {
    current?: string;
    cap?: string;
  };
  labelConfig?: {
    fontSize: number;
  };
  statsConfig?: {
    fontSize: number;
  };
  barConfig?: BarConfig;
  asset?: Assets;
}> = ({
  loading,
  current,
  cap,
  copies = { current: "", cap: "" },
  displayData: { current: displayCurrent, cap: displayCap } = {},
  labelConfig = { fontSize: 16 },
  statsConfig = { fontSize: 16 },
  barConfig = { height: 16, extraClassNames: "my-3", radius: 4 },
  asset,
}) => {
  const loadingText = useLoadingText();

  let percent = cap > 0 ? current / cap : 0;
  if (percent < 0) {
    percent = 0;
  } else if (percent > 1) {
    percent = 1;
  }
  percent *= 100;
  current = current > cap ? cap : current;

  return (
    <div className="w-100 px-2">
      <ProgressBar percent={percent} config={barConfig} />

      <div className="col-12 text-center">
        <h6 className="text-uppercase">
          $
          {loading
            ? loadingText
            : `${
                displayCurrent
                  ? displayCurrent
                  : `${(current).toLocaleString('en-US')} ${
                      asset ? getAssetDisplay(asset) : ""
                    }`
              }`}{" "}
          / $
          {loading
            ? loadingText
            : `${
                displayCap
                  ? displayCap
                  : `${(cap).toLocaleString('en-US')} ${
                      asset ? getAssetDisplay(asset) : ""
                    }`
              }`}
        </h6>
      </div>
    </div>
  );
};
export default CapacityBar;
