import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
interface DoughnutChart {
  data: any;
  byFilter: string;
  filterElements: any;
}
const DoughnutChart: React.FC<DoughnutChart> = (props: DoughnutChart) => {
  const options = {
    /*  datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start"
      } */
      
      tooltips: {
        callbacks: {
          label: function(tooltipItem:any, data:any) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue + '%';
          },
          title: function(tooltipItem:any, data:any) {
            return data.labels[tooltipItem[0].index];
          }
        }
      },

    
    legend: {
      display: false,
    },
  };
  const [filterElements, setFilterElements] = useState([]);

  useEffect(() => {
    setFilterElements(props.data.datasets[0].data);
  }, [props.data.datasets[0]]);

  return (
    <>
      <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-md-12 mb-2">
              <h6 className="text-uppercase d-inline mr-3">
                TVL Concentration
              </h6>
              <div className="tag d-inline">{props.byFilter}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Doughnut options={options} data={props.data} />
            </div>
          </div>

          {props.filterElements}
        </div>
      </div>
    </>
  );
};

export default DoughnutChart;
