import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Arbitrum from "../../assets/icons/blueswan/asset.svg";
import USD from "../../assets/icons/blueswan/usd.svg";
import USDC from "../../assets/icons/blueswan/usdc.svg";
import ETH from "../../assets/icons/blueswan/eth.svg";
import Matic from "../../assets/icons/blueswan/matic.svg";
import Avax from "../../assets/icons/blueswan/avax.svg";
import StrategyRowItem from "../Strategy/StrategyItem";
import { Link } from "react-router-dom";
import { BaseLink, BaseUnderlineLink } from "shared/lib/designSystem";
import ExternalLink from "shared/lib/assets/icons/externalLink";
import Tooltip from "../Tooltip";
import { fetchStrategyData } from "../../hooks/useFetchBlueSwanData";
import { BlueSwanVaultData } from "../../store/types";

const VaultCatalog: React.FC = ({}) => {
  const [error, setError] = useState(false);
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [highestAPY, setHighestAPY] = useState(0);
  const [lowestAPY, setLowestAPY] = useState(0);
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("purple-gradient");

    return () => {
      // 👇️ removing classes from body element
      // when the component unmounts
      document.body.classList.remove("purple-gradient");
    };
  }, []);

  useEffect(() => {
    console.log("here 13");
    async function fetch() {
      const { loading, error, data } = await fetchStrategyData();
      if (!error && data) {
        setVData(data);

        const highest = data.strategyApyRange.reduce(
          (previous: any, current: any) => {
            return current.weighted_apy.value > previous.weighted_apy.value
              ? current
              : previous;
          }
        );
        const lowest = data.strategyApyRange.reduce(
          (previous: any, current: any) => {
            return current.weighted_apy.value < previous.weighted_apy.value
              ? current
              : previous;
          }
        );
        setHighestAPY(parseInt((highest.weighted_apy.value * 100).toFixed(1)));
        setLowestAPY(parseInt((lowest.weighted_apy.value * 100).toFixed(1)));
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }
    if (!vdata) {
      fetch();
    }
  }, [vdata]);
  return (
    <div className="container my-5">
      {/* Main Page Description */}

      <div className="row section-heading">
        <div className="col-md-12">
          <div className="row">
            <div className="col-12">
              {/* <div className="tag">STRATEGIES</div> */}
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-6">
              <h1 className="heading">Automated Yield Strategies</h1>
            </div>
            <div className="col-md-6">
              <p>
                Yield Strategies are a series of automated investments that
                allocate funds in different crypto assets.
              </p>
              <p>
                There are different types of strategies, that are suitable for
                different market conditions and risk profiles. These types of
                strategies fall within the three yield categories: Market
                Neutral, Directional and Non-correlated.
              </p>
              <p>
                To learn more please visit our{" "}
                {
                  <BaseUnderlineLink
                    target={"_blank"}
                    to={
                      "https://blue-swan.gitbook.io/blue-swan-documentation/introduction-to-blue-swan/introduction"
                    }
                  >
                    DOCUMENTATION
                    <ExternalLink
                      style={{
                        marginLeft: 6,
                      }}
                    />
                  </BaseUnderlineLink>
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Page Description */}

      {/* Strategy Item */}
      <div className="row box bg radius p-4 my-4">
        <div className="col-12 mb-3">
          <div className="tag">Market Neutral</div>
        </div>

        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <h2>US$ Pseudo Delta Neutral Farming</h2>
              <p>
                This strategy aims to generate yield from providing liquidity to
                selected AMM liquidity pools (ie. Uniswap v3), while minimising
                the exposure to Impermanent Loss. This is done through
                establishing both a long and short position on the volatile pool
                crypto asset and making rebalances once the long / short gap
                reaches certain triggers related to debt ratios and price
                changes. Investors will have long exposure to US Dollars.
              </p>
            </div>

            <div className="col-md-6 offset-md-1">
              <div className="row no-gutters box bg box-clean box-items">
                <div className="col-md-4 p-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">Blockchains</div>
                    <div className="col-12 py-3 text-center">
                      <Tooltip title="Avalanche">
                        <img
                          src={Avalanche}
                          className="mr-1"
                          width="30"
                          alt=""
                        />
                      </Tooltip>
                      <Tooltip title="Optimism">
                        <img
                          src={Optimism}
                          className="mr-1"
                          width="30"
                          alt=""
                        />
                      </Tooltip>
                      <Tooltip title="Fantom">
                        <img src={Fantom} className="mr-1" width="30" alt="" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 p-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">Asset Exposure</div>
                    <div className="col-12 py-3 text-center">
                      <img
                        src={USD}
                        width={30}
                        className="mr-2 asset-exposure"
                      />
                      <h6 className="text-uppercase d-md-inline-block">
                        US Dollar
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 py-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">
                      APY Range (last 24 hours){" "}
                    </div>
                    <div className="col-12 py-3 text-center">
                      <h5 className="text-uppercase">
                        {lowestAPY}% <small>to</small> {highestAPY}%
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-12">
                  <span className="box-grey-copy">
                    This strategy performs better during LOW PRICE VOLATILITY
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <Link
                    to={"/vaults"}
                    className="bs-btn text-center blue w-100"
                  >
                    EXPLORE VAULTS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Strategy Item */}

      {/* Strategy Item */}
      <div className="row box bg radius p-4 my-4">
        <div className="col-12 mb-3">
          <div className="tag">Directional</div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <h2>Long ETH Yield</h2>
              <p>
                This strategy has the objective of providing long directional
                exposure to Ethereum while generating additional yield from
                investing ETH in different DEFI activities. The goal is to
                generate higher returns when comparing it to long and hold ETH
                returns.
              </p>
            </div>

            <div className="col-md-6 offset-md-1">
              <div className="row no-gutters box bg box-clean box-items">
                <div className="col-md-4 p-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">Blockchains</div>
                    <div className="col-12 py-3 text-center">
                      <Tooltip title="Avalanche">
                        <img
                          src={Avalanche}
                          className="mr-1"
                          width="30"
                          alt=""
                        />
                      </Tooltip>
                      <Tooltip title="Optimism">
                        <img
                          src={Optimism}
                          className="mr-1"
                          width="30"
                          alt=""
                        />
                      </Tooltip>
                      <Tooltip title="Fantom">
                        <img src={Fantom} className="mr-1" width="30" alt="" />
                      </Tooltip>
                      <Tooltip title="Arbitrum">
                        <img
                          src={Arbitrum}
                          className="mr-1"
                          width="30"
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 p-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">Asset Exposure</div>
                    <div className="col-12 py-3 text-center">
                      <img
                        src={ETH}
                        width={30}
                        className="mr-2 asset-exposure"
                      />
                      <h6 className="text-uppercase d-md-inline-block">
                        Ethereum
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 py-3 box-item">
                  <div className="row">
                    <div className="col-12 box-heading">
                      APY Range (last 24 hours){" "}
                    </div>
                    <div className="col-12 py-3 text-center">
                      <p>-</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-12">
                  <span className="box-grey-copy">
                    This strategy performs better during BULL market conditions
                  </span>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <Link
                    to={"/vaults"}
                    className="bs-btn text-center purple w-100 link-disabled"
                  >
                    Coming Soon
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Strategy Item */}
    </div>
  );
};

export default VaultCatalog;
