import BasicModal from "shared/lib/components/Common/BasicModal";
import styled from "styled-components";
import {
  BaseInput,
  BaseInputButton,
  BaseInputContainer,
  BaseInputLabel,
  BaseModalContentColumn,
  Title,
} from "shared/lib/designSystem";
import usdc from "../../assets/icons/blueswan/usdc.svg";
import React, { useState, useEffect } from "react";
import { BlueSwanVaultAddressMap } from "../../constants/constants";
import { getBlueSwanVaultByChain, VaultOptions } from "shared/lib/constants/constants";
import { Link } from "react-router-dom";
import DesktopActionForm from "../Vault/VaultActionsForm/DesktopActionForm";
import { useV2VaultData } from "shared/lib/hooks/web3DataContext";
import { formatBigNumber } from "shared/lib/utils/math";
import { switchChains } from "shared/lib/utils/chainSwitching";
import { useWeb3React as useEVMWallet } from "@web3-react/core";
import useVaultFeeData from "shared/lib/hooks/useVaultFeeData";
import { formatUnits } from "ethers/lib/utils";


const onClose = () => {};
const GenericButton = styled.button`
  border: 0;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 10px 15px;
  color: #bacfff;
  background: rgba(95, 133, 219, 0.08);
  &.active {
    background: rgba(95, 133, 219, 0.24);
  }
  display: block;
  text-align: center;
  &.blue {
    background: rgba(55, 117, 255, 0.32);
    color: #3775ff;
  }
`;
const DepositInputContainer = styled.div`
  font-weight: 600;
  input {
    background: transparent;
    border: 0;
    color: white;
    display: inline;
    font-weight: 600;
    &:focus {
      outline: none;
    }
  }
  span {
    padding-top: 2px;
    display: inline-block;
  }
`;
const walletData = [
  { title: "Wallet balance", value: "0 USDC" },
  { title: "Your deposits", value: "0 USDC" },
  { title: "Net APY", value: "20%" },
];


interface DepositModalProps {
  network: any;
  balance?: any;
  apy?: string;
  asset?: string;
  assetPrice?: string;
  capacity?: string;
  show: boolean;
  onClose: () => void;
  vaultFetchedData?: any;
  selectedVault?: string;
}

const DepositModal: React.FC<DepositModalProps> = ({
  show,
  onClose,
  balance,
  apy,
  asset,
  assetPrice,
  capacity,
  network,
  vaultFetchedData,
  selectedVault
}) => {
  //@ts-ignore
  const vaultData = BlueSwanVaultAddressMap[network || 'fantom'];  


  // if(vaultData.symbol !== selectedVault){
  //   switchChains();
  //    console.log("NOT ON SAME NETWORK PLEASE CHANGE")
  // }

  const {
    chainId,
    library: libraryEth,    
  } = useEVMWallet();

  useEffect(() => {
    const onSwitchChains = async () => {
      await switchChains(libraryEth, vaultData.chainId);      
    };
    console.log("vaultData.chainId", vaultData.chainId)
    console.log("chainId", chainId)
    if (vaultData.chainId !== chainId && show) {
      onSwitchChains();
    }
  }, [selectedVault, chainId]);

  const {
    data: {
      pricePerShare,
      decimals,
      lockedBalanceInAsset,
      depositBalanceInAsset,
      withdrawals      
    },
  } = useV2VaultData(vaultData.symbol);

  const hideOriginModal = () => {
    // onClosee();
  }
  // useRedirectOnSwitchChain(network.chainId);
  const { responses: feeData, loading: loadingFees } = useVaultFeeData(
    vaultData.symbol
  );

  const modalData2 = [
    {
      title: "Management Fee",
      value: `${formatUnits(feeData.adminFee || 0, 12)} %`,
    },
    {
      title: "Performance Fee",
      value: `${formatUnits(feeData.performanceFee || 0, 12)} %`,
    },
    {
      title: "Withdrawal Fee",
      value: `${formatUnits(feeData.withdrawalFee || 0, 13)} %`,
    },
  ];

  const modalData1 = [
    { title: "Asset Exposure", value: "USDC" },
    { title: "USDC Price", value: "$1.00 " },
    { title: "Vault Capacity", value: `${ formatBigNumber(lockedBalanceInAsset || "0", 6)} / 450,000 USDC` },
  ];

  

  return (
    <>
      <BasicModal
        backgroundColor="#000000"
        maxWidth={500}
        show={show}
        headerBackground
        height={780}
        onClose={onClose}
      >
        <>
          <BaseModalContentColumn
            marginTop={0}
            className="justify-content-start px-3"
          >
            <h6 className="font-weight-600 text-uppercase pt-2">
              Deposit &amp; Withdraw
            </h6>
          </BaseModalContentColumn>
          <BaseModalContentColumn
            marginTop={20}
            className="justify-content-start"
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="flex-column">
                    <DesktopActionForm
                      onClose={onClose}
                      vault={{
                        vaultOption: vaultData.symbol,
                        vaultVersion: "v2",
                        vaultFetchedData: vaultFetchedData,
                      }}
                      hideOriginModal={hideOriginModal}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h5 className="text-center">{vaultData.symbol}</h5>
                </div>
              </div>
              <div className="row vault-analytics-grid pt-2">
                <div className="col-12">
                  <div className="row no-gutters box radius box-clean bg box-items pb-3 px-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          {modalData1.map((d, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 my-2 analytics-row">
                                  <div className="col-md-12 ">
                                    <h6 className="text-center">{d.title}</h6>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <h5 className="text-uppercase">
                                      {d.value}
                                    </h5>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <div className="col-md-6">
                          {modalData2.map((d, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 my-2 analytics-row">
                                  <div className="col-md-12 ">
                                    <h6 className="text-center">{d.title}</h6>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <h5 className="text-uppercase">
                                      {d.value}
                                    </h5>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        <div className="col-md-12 mt-2">
                          <GenericButton
                            className="btn-analytics"
                            onClick={() => {
                              onClose();
                            }}
                          >
                            <Link to={`/vault/${network}/summary`} className="">
                              Analytics &amp; more info
                            </Link>
                          </GenericButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container">
              <div className="row no-gutters">
                <div className="col-md-6">
                  <GenericButton className="w-100 active">
                    DEPOSIT
                  </GenericButton>
                </div>
                <div className="col-md-6">
                  <GenericButton className="w-100">Withdraw</GenericButton>
                </div>
              </div>

              <div className="row vault-analytics-grid my-2">
                <div className="col-12">
                  <div className="row no-gutters box radius box-clean bg box-items pb-3 px-3">
                    <div className="col-12">
                      <div className="row  py-3">
                        <div className="col-md-6">
                          <GenericButton className="active">MAX</GenericButton>
                        </div>
                        <div className="col-md-6 text-right">
                          <DepositInputContainer>
                            <div className="row no-gutters pt-1">
                              <div className="col">
                                <input
                                  className="form-input text-right w-50"
                                  value={0.0}
                                />
                              </div>
                              <div className="col">
                                <span>USDC</span>
                                <span className="mx-1">
                                  <img src={usdc} />
                                </span>
                              </div>
                            </div>
                          </DepositInputContainer>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {walletData.map((d, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row no-gutters box radius box-items box-clean bg pt-3 pb-1 px-3 my-2 analytics-row">
                                  <div className="col-md-6 ">
                                    <h6 className="text-md-left">{d.title}</h6>
                                  </div>
                                  <div className="col-md-6 text-md-right">
                                    <h5 className="text-uppercase">
                                      {d.value}
                                    </h5>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <GenericButton className="active w-100">
                        DEPOSIT
                      </GenericButton>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row vault-analytics-grid">
                <div className="col-12">
                  <div className="row no-gutters box radius box-clean bg box-items pb-3 px-3">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          {modalData1.map((d, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 my-2 analytics-row">
                                  <div className="col-md-12 ">
                                    <h6 className="text-center">{d.title}</h6>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <h5 className="text-uppercase">
                                      {d.value}
                                    </h5>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <div className="col-md-6">
                          {modalData2.map((d, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row no-gutters box radius box-items box-clean bg pt-2 pb-1 my-2 analytics-row">
                                  <div className="col-md-12 ">
                                    <h6 className="text-center">{d.title}</h6>
                                  </div>
                                  <div className="col-md-12 text-center">
                                    <h5 className="text-uppercase">
                                      {d.value}
                                    </h5>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>

                        <div className="col-md-12">
                          <GenericButton
                            className="blue w-100"
                            onClick={() => {
                              onClose();
                            }}
                          >
                            <Link to={`/vault/${network}/summary`} className="">
                              Analytics &amp; more info
                            </Link>
                          </GenericButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </BaseModalContentColumn>
        </>
      </BasicModal>
    </>
  );
};

export default DepositModal;
