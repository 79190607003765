import { useEffect, useState } from "react";
import {
  useAssetBalance,
  useV2VaultData,
} from "shared/lib/hooks/web3DataContext";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import {
  formatAmountUSD,
  formatBigNumber,
  formatVaultBalance,
} from "shared/lib/utils/math";
import {
  fetchStrategyData,
  fetchUserPortfolioValue,
} from "../../hooks/useFetchBlueSwanData";
import useVaultActionForm from "../../hooks/useVaultActionForm";
import { BlueSwanVaultData } from "../../store/types";
import Tooltip from "../Tooltip";
import {
  BlueSwanVaultAddressMap,
  formatPercentage,
  LEVERAGE,
} from "../../constants/constants";
import useVaultStrategyData from "shared/lib/hooks/useVaultStrategyData";
import useFetchUserEvents from "shared/lib/hooks/useFetchUserEvents";
import { ethers } from "ethers";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";
import { VaultDataFromRow } from "../../pages/Portfolio/PortfolioPage";
import useFetchVaultDataHistorical from "shared/lib/hooks/useFetchVaultDataHistorical";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

interface VaultPortfolioRowProps {
  network: string;
  setParentData: (data: VaultDataFromRow) => void;
  onDeposit: () => void;
}

const VaultPortfolioRow: React.FC<VaultPortfolioRowProps> = ({
  network,
  setParentData,
  onDeposit,
}) => {
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>();
  const [error, setError] = useState(false);

  //@ts-ignore
  const chain = BlueSwanVaultAddressMap[network];

  const {
    data: {
      asset,
      totalSupply,
      balanceOf,
      decimals,
      lockedBalanceInAsset,
      pricePerShare,
      totalBalance,
    },
    loading,
  } = useV2VaultData(chain.symbol);

  const { account } = useWeb3Wallet();
  const { responses: strategyData } = useVaultStrategyData(chain.symbol);
  const { responses: userEvents, loading: ueLoading } = useFetchUserEvents(
    chain.symbol
  );
  const date = new Date();
  date.setDate(date.getDate() - 1);
  const { response: dataDayAgo, loading: daLoading } =
    useFetchVaultDataHistorical(chain.symbol, date.toISOString());
  date.setDate(date.getDate() - 6);
  const { response: dataWeekAgo, loading: waLoading } =
    useFetchVaultDataHistorical(chain.symbol, date.toISOString());
  const [roi, setRoi] = useState(0);
  const [currentDeposit, setCurrentDeposit] = useState(0);
  const [returns, setReturns] = useState(0);
  const [equity, setEquity] = useState(0);
  const [debt, setDebt] = useState(0);
  useEffect(() => {
    const totalEquity = strategyData.reduce(
      (acc, curr) => acc + curr.equity,
      0
    );
    const totalDebt = strategyData.reduce((acc, curr) => acc + curr.debtUsd, 0);
    if (!loading) {
      const userEquity =
        (+ethers.utils.formatUnits(balanceOf, decimals) * totalEquity) /
        +ethers.utils.formatUnits(totalSupply, decimals);
      const userDebt =
        (+ethers.utils.formatUnits(balanceOf, decimals) * totalDebt) /
        +ethers.utils.formatUnits(totalSupply, decimals);
      setEquity(userEquity || 0);
      setDebt(userDebt || 0);
    }
    
  }, [strategyData]);

  useEffect(() => {
    if (userEvents && userEvents.length > 0) {
      const deposited = userEvents.reduce(
        (acc, curr) =>
          curr.eventType === "deposit" ? acc + curr.usdValue : acc,
        0
      );
      const withdrawn = userEvents.reduce(
        (acc, curr) =>
          curr.eventType === "withdrawal" ? acc + curr.usdValue : acc,
        0
      );
      
      if (totalSupply.gt(0)) {
        const currentBalance = +ethers.utils.formatUnits(
          balanceOf.mul(pricePerShare),
          24
        );
        const nav = currentBalance + withdrawn;
        const returnOnInvestment = nav - deposited;
        const roi = ((currentBalance+withdrawn-deposited)/deposited);

        const positionValue = Number((currentBalance * LEVERAGE).toFixed(1))
        
        setRoi(roi);
        setReturns(returnOnInvestment);
        setCurrentDeposit(positionValue);
      }
    }
  }, [userEvents, balanceOf, strategyData]);

  useEffect(() => {
    const dataForParentComponent: VaultDataFromRow = {
      positionValue: currentDeposit,
      positionValue1dAgo: dataDayAgo.data.positionValue,
      positionValue7dAgo: dataWeekAgo.data.positionValue,
      apy: vdata?.apy || 0,
      apy1dAgo: vdata?.apy24 || 0,
      apy7dAgo: vdata?.apy7d || 0,
      block: Infinity,
      block1dAgo: dataDayAgo.block,
      block7dAgo: dataWeekAgo.block,
      transactions: userEvents,
      roi : roi,
      balance: +ethers.utils.formatUnits(
        balanceOf.mul(pricePerShare),
        24
      ),
      isLoaded: !daLoading && !waLoading && !ueLoading,
    };
    setParentData(dataForParentComponent);
  }, [vdata, currentDeposit, userEvents, dataDayAgo, dataWeekAgo]);

  const { handleActionTypeChange, vaultActionForm, handleMaxClick } =
    useVaultActionForm(chain.symbol);
  const { balance: userAssetBalance } = useAssetBalance(
    vaultActionForm.depositAsset || asset
  );

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchStrategyData(network);
      if (!error && data) {
        setVData(data);
      }
      if (error) {
        setError(error);
      }
    }
    if (!vdata) {
      fetch();
    }
  }, [vdata]);

  if (daLoading || waLoading || ueLoading) {
    return (
      <div className="row box bg radius py-3 box-items px-2 my-3">
        <div className="col-12">
          <SkeletonTheme baseColor="#bdf7ff0a" highlightColor="#5f85db14">
            <p>
              <Skeleton className="my-1" count={3} height={24} />
            </p>
          </SkeletonTheme>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-11 mx-auto col-md-12">
        <div className="row box bg radius py-3 box-items px-2 my-3">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="d-md-flex justify-content-around">
                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">Vault</div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">{chain.symbol}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="text-center px-1 portfolio-row-fixed-size">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">Chain</div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <Tooltip title={network}>
                          <img
                            src={
                              network == "avalanche"
                                ? Avalanche
                                : network == "fantom"
                                ? Fantom
                                : Optimism
                            }
                            className="mr-1 d-inline-block"
                            width="20"
                            alt=""
                          />
                        </Tooltip>
                        <h6 className="text-uppercase d-inline">{network}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">
                        Position Value
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">$ 
                        {lockedBalanceInAsset
                        ? parseInt(`${Number(
                          formatBigNumber(
                            lockedBalanceInAsset.mul(pricePerShare).div(1e12),
                            12
                          )
                        ) * LEVERAGE}`)
                        : 0}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">
                        Equity Value
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">
                          $ {equity.toFixed(1)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12 box-heading pb-3">Debt Value</div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">
                          $ {(debt.toFixed(1))}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">ROI</div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">
                          {roi ? formatPercentage(roi) : `${0} %`}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">
                        Annualized ROI
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">
                          { formatPercentage((((roi / 100) * 365)))}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="text-center px-1 ">
                    <div className="row">
                      <div className="col-12 box-heading pb-3">
                        Return (US$)
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 text-center">
                        <h6 className="text-uppercase">${returns.toFixed(2)}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="tag strategy my-2">Strategy status: Active</div>
              </div>
              <div className="col-md-6 text-md-right">
                {/* <div className="tag investment mr-auto">
                  Your deposits:{" "}
                  <span>
                    $
                    {lockedBalanceInAsset
                      ? Number(
                          formatBigNumber(
                            lockedBalanceInAsset.mul(pricePerShare).div(1e12),
                            12
                          )
                        )
                      : 0}
                  </span>
                </div> */}
                <button
                  className="bs-btn blue d-inline-block px-5 mx-2 w-50 py-1 "
                  onClick={onDeposit}
                >
                  Manage
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaultPortfolioRow;
