import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import VaultCatalog from "../../components/Vault/VaultCatalog";

const Homepage = () => {
  const history = useHistory();

  return (
    <>
      {/* <LendBanner /> */}
      {/* <ProductCatalogue
        variant="webapp"
        onVaultPress={(vault, version) =>
          history.push(getVaultURI(vault, version))
        }
      /> */}
      <VaultCatalog />
      {/* <VaultDetail /> */}
    </>
  );
};

export default Homepage;
