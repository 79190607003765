import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import OverviewBoxRow from "../../components/Analytics/OverviewBoxRow";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg";
import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Optimism from "../../assets/icons/blueswan/optimism.svg";
import TimeChart from "../../components/Chart/TimeChart";
import DoughnutChart from "../../components/Chart/DoughnutChart";
import { GenericButton } from "../../components/Common/Buttons";
import OverviewMenu from "../../components/Analytics/OverviewMenu";
import Tooltip from "../../components/Tooltip";
import { BlueSwanVaultData } from "../../store/types";
import { fetchOverviewData } from "../../hooks/useFetchBlueSwanData";
import { VaultList } from "shared/lib/constants/constants";
import { useV2VaultData } from "shared/lib/hooks/web3DataContext";
import { formatUnits } from "ethers/lib/utils";
import { formatAmountUSD, formatBigNumber } from "shared/lib/utils/math";
import { useV2VaultsData } from "shared/lib/hooks/web3DataContext";
import useWeb3Wallet from "shared/lib/hooks/useWeb3Wallet";

import FieldLoader from "../../components/Common/FieldLoader";
import useVaultStrategyData from "shared/lib/hooks/useVaultStrategyData";

const OverviewPage = () => {
  //
  const [error, setError] = useState(false);
  const [vdata, setVData] = useState<BlueSwanVaultData | undefined>(); 
  const {
    data: { numPositions: positionsAvax, totalBalance: balanceAvax },
  } = useV2VaultData(VaultList[0]);
  const {
    data: { numPositions: positionsFtm, totalBalance: balanceFtm },
  } = useV2VaultData(VaultList[1]);
  const {
    data: { numPositions: positionsOp, totalBalance: balanceOp },
  } = useV2VaultData(VaultList[2]);
  const { responses: strategyDataAvax, loading: loadingTVTAvax } = useVaultStrategyData(VaultList[0]);
  const { responses: strategyDataFtm, loading: loadingTVTFtm } = useVaultStrategyData(VaultList[1]);
  const { responses: strategyDataOp, loading: loadingTVTOpt } = useVaultStrategyData(VaultList[2]);
  
  const [tvl, setTvl] = useState(0);
  const [totalValueTransacted, setTotalValueTransacted] = useState(0);
  const [protocolsCount, setProtocolsCount] = useState(0);
  const [vaultsCount, setVaultsCount] = useState(0);
  const [positionsCount, setPositionsCount] = useState(0);
  const { data: vaultsData } = useV2VaultsData();
  const {
    ethereumProvider,
    chainId,
    active,
    account: web3Account,
  } = useWeb3Wallet();

  useEffect(() => {
    const avaxSupplied = strategyDataAvax.reduce(
      (acc, curr) => acc + curr.stableSuppliedUsd,
      0
    );
    const ftmSupplied = strategyDataFtm.reduce(
      (acc, curr) => acc + curr.stableSuppliedUsd,
      0
    );
    const opSupplied = strategyDataOp.reduce(
      (acc, curr) => acc + curr.stableSuppliedUsd,
      0
    );
    setTotalValueTransacted(avaxSupplied + ftmSupplied + opSupplied);
  }, [strategyDataAvax, strategyDataFtm, strategyDataOp]);
  // const [concentrationByNetwork, setConcentrationByNetwork] = useState([]);
  const concentrationByNetwork = [
    parseInt(formatUnits(balanceAvax, 6)),
    parseInt(formatUnits(balanceFtm, 6)),
    parseInt(formatUnits(balanceOp, 6)),
  ];

  useEffect(() => {
    let total = 0;
    setVaultsCount(Object.keys(vaultsData).length);
    for (const [key, value] of Object.entries(vaultsData)) {
      //@ts-ignore
      total += value.totalBalance
        ? Number(
            formatBigNumber(
              value.totalBalance.mul(value.pricePerShare).div(1e12),
              12
            )
          )
        : 0;
    }
    setTvl(total);
  }, [vaultsData]);

  useEffect(() => {
    async function fetch() {
      const { loading, error, data } = await fetchOverviewData();

      if (!error && data) {
        /* Set Vault Data */
        setVData(data);

        setProtocolsCount(data.overview.protocols.value);
        // setVaultsCount(data.overview.vaults.value);
        setPositionsCount(data.overview.positions.value);
      }
      if (error) {
        setError(error);
        console.log("Vault Apy Data error");
      }
    }

    fetch();
  }, [vdata]);

  const data: any = [
    {
      title: "Total Value Locked",
      helpText: "Amount of $ deposited in our Vaults",
      mainData: (
        <h3>{`$ ${parseInt(
          (+formatUnits(
            balanceAvax.add(balanceFtm).add(balanceOp),
            6
          )).toString()
        )}`}</h3>
      ),
    },
    {
      title: "Total Value Transacted",
      helpText: "Total value that went on the protocol since inception",
      mainData:
        (!loadingTVTAvax && !loadingTVTFtm && !loadingTVTOpt) ? (
          <h3>${parseInt("" + totalValueTransacted)}</h3>
        ) : (
          <FieldLoader />
        ),
    },
    {
      title: "Blockchains Deployed",
      helpText: "Number of Blockchains we use for our investments",
      mainData: (
        <>
          <Tooltip title="Avalanche">
            <img src={Avalanche} className="mr-1" width="30" alt="" />
          </Tooltip>
          <Tooltip title="Optimism">
            <img src={Optimism} className="mr-1" width="30" alt="" />
          </Tooltip>
          <Tooltip title="Fantom">
            <img src={Fantom} className="mr-1" width="30" alt="" />
          </Tooltip>
        </>
      ),
    },
  ];

  const dataProtocol: any = [
    {
      title: "Protocols Integrated",
      helpText: "Number of protocols we interact to perform our strategies",
      mainData: <h3>{protocolsCount}</h3>,
    },
    {
      title: "Vaults",
      helpText: "Number of strategies deployed into vaults",
      mainData: <h3>{VaultList.length}</h3>,
    },
    {
      title: "Positions",
      helpText: "Number of investments in our vaults",
      mainData: (
        <h3>{positionsAvax?.add(positionsFtm).add(positionsOp).toNumber()}</h3>
      ),
    },
  ];

  const dataDoughnutChart1 = {
    labels: ["Swan 01" /* , "Swan 02", "Swan 03" */],
    datasets: [
      {
        label: "TVL Concentration",
        data: [100 /* , 27.5, 10.5 */],
        backgroundColor: [
          "rgba(95, 133, 219, 1)",
          /* "rgba(95, 133, 219, .6)",
          "rgba(95, 133, 219, .3)", */
        ],
        borderWidth: 0,
      },
    ],
  };
  const dataDoughnutChart2 = {
    labels: ["Avalanche", "Fantom", "Optimism"],
    datasets: [
      {
        label: "TVL Concentration",
        data: concentrationByNetwork,

        /* backgroundColor: [
          "rgba(189, 247, 255, 1)",
          "rgba(189, 247, 255, .6)",
          "rgba(189, 247, 255, .3)",
        ], */
        backgroundColor: [
          "rgba(95, 133, 219, 1)",
          "rgba(95, 133, 219, .7)",
          "rgba(95, 133, 219, .3)",
        ],
        borderWidth: 0,
      },
    ],
  };

  const filterElements1 = (
    <>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2 py-4">
          <div className="filterElement">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, 1)" }}
            ></span>
            Swan 01 / 100%
          </div>

          {/* <div className="filterElement">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, .6)" }}
            ></span>
            Swan 02 / 37.5%
          </div>
          <div className="filterElement">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, .3)" }}
            ></span>
            Swan 03 / 12.5%
          </div> */}
        </div>
      </div>
    </>
  );

  const filterElements2 = (
    <>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2 py-4">
          <div className="filterElement text-center text-md-left blue">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, 1)" }}
            ></span>
            <img className="mr-2" src={Avalanche} />
            Avalanche / {parseInt(formatUnits(balanceAvax, 6))} %
          </div>

          <div className="filterElement text-center text-md-left blue">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, .7)" }}
            ></span>
            <img className="mr-2" src={Fantom} />
            Fantom {parseInt(formatUnits(balanceFtm, 6))} %
          </div>
          <div className="filterElement text-center text-md-left blue">
            <span
              className="colorBox"
              style={{ backgroundColor: "rgba(95, 133, 219, .3)" }}
            ></span>
            <img className="mr-2" src={Optimism} />
            Optimism {parseInt(formatUnits(balanceOp, 6))} %
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="container py-5">
        {/* Heading */}
        <div className="row section-heading pb-5">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12">
                <div className="tag">VAULTS / Analytics</div>
              </div>
            </div>
          </div>
        </div>
        {/* Heading */}

        <div className="row">
          <div className="col-md-3">
            <OverviewMenu />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-12">
                <h2>Blue Swan Protocol Overview</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <OverviewBoxRow data={data} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <OverviewBoxRow data={dataProtocol} />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <TimeChart
                  title="Total Value Locked (USD)"
                  tags={["In Defi"]}
                  showFilters={true}
                /> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <DoughnutChart
                  data={dataDoughnutChart1}
                  byFilter="by Vault type"
                  filterElements={filterElements1}
                />
              </div>
              <div className="col-md-6">
                <DoughnutChart
                  data={dataDoughnutChart2}
                  byFilter="by chain"
                  filterElements={filterElements2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
