import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const bsEndpoint =
  process.env.REACT_APP_ENVIRONMENT === "local"
    ? "http://localhost:3300"
    : process.env.REACT_APP_BS_SERVER_URL;
const httpLink = new HttpLink({
  uri: `${bsEndpoint}/graphql`,
});

export const blueSwanClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({ addTypename: false }),
  queryDeduplication: true,
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  },
});
