import { CHAINID, isDevelopment } from "shared/lib/utils/env";
import {
  VaultNameOptionMap,
  VaultOptions,
  VaultVersion,
} from "shared/lib/constants/constants";
import { Airdrop, AirdropBreakdown, AirdropProof } from "../models/airdrop";
import ProofKovanData from "../data/proof-kovan.json";
import ProofData from "../data/proof.json";
import AirdropKovanData from "../data/airdrop-kovan.json";
import AirdropData from "../data/airdrop.json";
import BreakdownKovanData from "../data/breakdown-kovan.json";
import BreakdownData from "../data/breakdown.json";

export const proof: AirdropProof = isDevelopment() ? ProofKovanData : ProofData;

export const airdrop: Airdrop = isDevelopment()
  ? AirdropKovanData
  : AirdropData;

export const breakdown: AirdropBreakdown = isDevelopment()
  ? BreakdownKovanData
  : BreakdownData;

export const getVaultURI = (
  vaultOption: VaultOptions,
  vaultVersion: VaultVersion = "v1"
): string => {
  switch (vaultVersion) {
    case "v1":
      return `/theta-vault/${
        Object.keys(VaultNameOptionMap)[
          Object.values(VaultNameOptionMap).indexOf(vaultOption)
        ]
      }`;
    case "earn":
      return `/${
        Object.keys(VaultNameOptionMap)[
          Object.values(VaultNameOptionMap).indexOf(vaultOption)
        ]
      }`;
    /* case "v2":
      return `/v2/theta-vault/${
        Object.keys(VaultNameOptionMap)[
          Object.values(VaultNameOptionMap).indexOf(vaultOption)
        ]
      }`; */
    case "v2":
      return `/vault/${
        Object.keys(VaultNameOptionMap)[
          Object.values(VaultNameOptionMap).indexOf(vaultOption)
        ]
      }/summary`;
  }
};

export const BlueSwanVaultAddressMap: {
  [vault in "fantom" | "avalanche" | "optimism"]: {
    chainId: number;
    address: string;
    symbol: string;
    withdrawalFee: string;
    managementFee: string;
    performanceFee: string;
  };
} = {
  fantom: isDevelopment()
    ? {
        chainId: CHAINID.FANTOM_MAINNET,
        address: "0x952614E3D0fbD87999b4d1041C831690feF8395f",
        symbol: "S01FTM",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      }
    : {
        chainId: CHAINID.FANTOM_MAINNET,
        address: "0x952614E3D0fbD87999b4d1041C831690feF8395f",
        symbol: "S01FTM",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      },
  avalanche: isDevelopment()
    ? {
        chainId: CHAINID.AVAX_FUJI,
        address: "0x9c60B31a3c515965854f80428bA9E487eDA0BEA5",
        symbol: "S01AVAX",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      }
    : {
        chainId: CHAINID.AVAX_MAINNET,
        address: "0x9c60B31a3c515965854f80428bA9E487eDA0BEA5",
        symbol: "S01AVAX",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      },
  optimism: isDevelopment()
    ? {
        chainId: CHAINID.OPTIMISM_MAINNET,
        address: "0x35a7f3fCEB2Be50B9f00620A2348aef6f0a3FE6D",
        symbol: "S01OP",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      }
    : {
        chainId: CHAINID.OPTIMISM_MAINNET,
        address: "0x35a7f3fCEB2Be50B9f00620A2348aef6f0a3FE6D",
        symbol: "S01OP",
        withdrawalFee: "0.5%",
        managementFee: "2%",
        performanceFee: "20%",
      },
};

export const formatPercentage = (n: number | undefined) => {
  return n !== undefined ? (n * 100).toFixed(2) + "%" : "-";
};

export const LEVERAGE = 3;