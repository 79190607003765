import { blueSwanClient } from "../apollo/client";
import gql from "graphql-tag";

interface Response {
  loading: boolean;
  error: boolean | undefined;
  data: any | undefined;
}

const GET_VAULT_ALL_DATA = gql`
  query getVaultAPY($network: String, $range24h: String, $range7d: String) {
    strategyApyRange: getStrategyApyRange
    apy: getVaultAPY(network: $network)
    apy24: getVaultAPY(network: $network, range: $range24h)
    apy7d: getVaultAPY(network: $network, range: $range7d)
    apyRange: getAPYRange
  }
`;

const GET_VAULT_DATA = gql`
  query getVaultAPY(
    $network: String
    $range24h: String
    $range7d: String
    $range14d: String
  ) {
    apy: getVaultAPY(network: $network)
    apy24: getVaultAPY(network: $network, range: $range24h)
    apy7d: getVaultAPY(network: $network, range: $range7d)
    apy14d: getVaultAPY(network: $network, range: $range14d)
    apyRange: getAPYRange(network: $network)
    positions: getVaultPositions(network: $network)
    apyChart: getApyChart(network: $network)
    strategySummary: getStrategySummary(network: $network)
    portfolioPerformance: getPortfolioPerformance(network: $network)
    portfolioRiskMetrics: getPortfolioRiskMetrics(network: $network)
  }
`;
const GET_VAULT_OVERVIEW = gql`
  query overview {
    concentration: getVaultConcentrationByNetwork
    overview: getVaultOverviewData
  }
`;
const GET_USER_PORTFOLIO_VALUE_DATA = gql`
  query user($userAddress: String, $days: Int, $platform: String) {
    value: getHistoricalUserBalanceInProtocolPortfolio(
      userAddress: $userAddress
      days: $days
      platform: $platform
    )
    apy24: getVaultAPY(network: "", range: "24h")
    apy7d: getVaultAPY(network: "", range: "7d")
  }
`;

const GET_PORTFOLIO_BACKTESTING = gql`
      query getBacktest($bsSymbol: String!){
        getBacktest(bsSymbol:$bsSymbol){
          createdAt
          chain
          isPortfolio
          bsSymbol
          meanDrawDown
          numberOfRebalances
          endDate
          begginingDate
          sharpeRatio
          annualizedReturn
          optimizationMethodology
          protocol
          annualVolatility
          volatileAsset
          backTestingData{
            date
            cummulative_return
            token1_price
          }
        }
      }
`;

export const fetchOverviewData = async (): Promise<any> => {
  const { loading, error, data } = await blueSwanClient.query<any>({
    query: GET_VAULT_OVERVIEW,
    fetchPolicy: "cache-first",
  });

  return {
    loading: loading,
    error: error,
    data: data,
  };
};
export const fetchStrategyData = async (network: string = ""): Promise<any> => {
  const { loading, error, data } = await blueSwanClient.query<any>({
    query: GET_VAULT_ALL_DATA,
    variables: {
      network: network,
      range24h: "24h",
      range7d: "7d",
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading: loading,
    error: error,
    data: data,
  };
};

export const fetchVaultSummaryData = async (
  network: string = ""
): Promise<any> => {
  const { loading, error, data } = await blueSwanClient.query<any>({
    query: GET_VAULT_DATA,
    variables: {
      network: network,
      range24h: "24h",
      range7d: "7d",
      range14d: "14d",
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading: loading,
    error: error,
    data: data,
  };
};

export const fetchUserPortfolioValue = async (
  userAddress: string
): Promise<any> => {
  const { loading, error, data } = await blueSwanClient.query<any>({
    query: GET_USER_PORTFOLIO_VALUE_DATA,
    variables: {
      userAddress: userAddress,
      days: 15,
      platform: "blueswan_protocol",
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading: loading,
    error: error,
    data: data,
  };
};

export const fetchPortfolioBacktestng = async (
  bsSymbol: string
): Promise<any> => {
  const { loading, error, data } = await blueSwanClient.query<any>({
    query: GET_PORTFOLIO_BACKTESTING,
    variables: {
      bsSymbol: bsSymbol     
    },
    fetchPolicy: "cache-first",
  });

  return {
    loading: loading,
    error: error,
    data: data,
  };
};

// export default fetchBlueSwanData;
