import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useRouteMatch } from "react-router-dom";
import { useWeb3Wallet } from "shared/lib/hooks/useWeb3Wallet";

import HeaderLogo from "./HeaderLogo";
import HeaderLogoWhite from "./HeaderLogoWhite";
import { URLS } from "shared/lib/constants/constants";
import colors from "shared/lib/designSystem/colors";
import sizes from "shared/lib/designSystem/sizes";
import { Title, BaseLink } from "shared/lib/designSystem";
import MenuButton from "shared/lib/components/Common/MenuButton";
import { NavItemProps, MobileMenuOpenProps } from "./types";
import AccountStatus from "../Wallet/AccountStatus";
import theme from "shared/lib/designSystem/theme";
import MobileOverlayMenu from "shared/lib/components/Common/MobileOverlayMenu";
import NetworkSwitcherButton from "../NetworkSwitcher/NetworkSwitcherButton";
import NotificationButton from "../Notification/NotificationButton";
import { isEthNetwork } from "shared/lib/constants/constants";
import ExternalLink from "shared/lib/assets/icons/externalLink";
import { useGlobalState } from "shared/lib/store/store";
import FilterDropdown from "shared/lib/components/Common/FilterDropdown";
import { useHistory } from "react-router-dom";

const HeaderContainer = styled.div<MobileMenuOpenProps>`
  height: ${theme.header.height}px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${colors.border};

  @media (max-width: ${sizes.xl}px) {
    padding: 16px 24px;
    border-bottom: none;
  }

  z-index: ${(props) => (props.isMenuOpen ? 50 : 10)};

  // The backdrop for the menu does not show up if we enable the backdrop-filter
  // for the header nav. To get around that, just set 'none'
  ${(props) => {
    if (props.isMenuOpen) {
      return null;
    }

    return `
      backdrop-filter: blur(40px);
      /**
       * Firefox desktop come with default flag to have backdrop-filter disabled
       * Firefox Android also currently has bug where backdrop-filter is not being applied
       * More info: https://bugzilla.mozilla.org/show_bug.cgi?id=1178765
       **/
      @-moz-document url-prefix() {
        background-color: rgba(0, 0, 0, 0.9);
      }
    `;
  }}

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(255, 255, 255, 0.01);
  }
`;

const LogoContainer = styled.div`
  padding-left: 40px;
  margin-right: auto;
  z-index: 1000;

  @media (max-width: ${sizes.xl}px) {
    padding-left: 0;
  }
`;

const HeaderButtonContainer = styled.div`
  display: inline-block;
  margin-right: 8px;
  z-index: 1;
`;

const HeaderAbsoluteContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;

  @media (max-width: ${sizes.xl}px) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
`;

const NavItem = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})<NavItemProps>`
  padding: 0px 8px;
  height: 100%;

  /* opacity: ${(props) => (props.isSelected ? "1" : "0.48")}; */

  &:hover {
    /* opacity: ${(props) =>
      props.isHighlighted
        ? "1"
        : props.isSelected
        ? theme.hover.opacity
        : "1"}; */
    opacity: ${(props) => (props.isSelected ? "1" : "0.48")};
  }

  @media (max-width: ${sizes.xl}px) {
    padding: 0px 0px 40px 48px;
  }
  text-transform: capitalize !important;
  font-weight: 600;
`;

const NavLinkText = styled(Title)`
  letter-spacing: 1.5px;
  font-size: 14px;
  line-height: 20px;

  @media (max-width: ${sizes.xl}px) {
    font-size: 24px;
  }
  text-transform: capitalize !important;
  font-weight: 600;
`;

const SecondaryMobileNavItem = styled.div`
  display: none;

  @media (max-width: ${sizes.xl}px) {
    display: flex;
    padding: 0px 0px 24px 48px;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${sizes.xl}px) {
    display: flex;
  }
`;

const InstitutionalButton = styled(Link)`
  padding: 10px 15px;
  border: 0;
  color: white;
  background: rgba(95, 133, 219, 0.32);
  border-radius: 4px;
  display: inline-block;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    color: white;
  }
`;

const Header = () => {
  const history = useHistory();
  const { active, chainId } = useWeb3Wallet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const product = useRouteMatch({ path: "/", exact: true });
  const portfolio = useRouteMatch({ path: "/portfolio", exact: true });
  const staking = useRouteMatch({ path: "/staking", exact: true });
  const headerRef = useRef<HTMLDivElement>(null);
  const [, setComponentRefs] = useGlobalState("componentRefs");

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (headerRef.current) {
      setComponentRefs((prev: any) => ({
        ...prev,
        header: headerRef.current as HTMLDivElement,
      }));
    }
  }, [headerRef, setComponentRefs]);

  const openLink = useCallback(
    (option) => {
      if (option === "VAULTTOKEN") {
        window.open(URLS.discord);
      } else {
        window.open(URLS.twitter);
      }
      setIsMenuOpen(false);
    },
    [history]
  );
  const [isWhiteHeader, setIsWhiteHeader] = useState(false);

  useEffect(() => {
    setIsWhiteHeader(history.location.pathname.includes("institutional"));
  }, [history.location.pathname]);

  const renderLinkItem = (
    title: string,
    to: string,
    isSelected: boolean,
    primary: boolean = true,
    external: boolean = false,
    isHighlighted: boolean = false
  ) => {
    return (
      <BaseLink
        to={to}
        target={external ? "_blank" : undefined}
        rel={external ? "noreferrer noopener" : undefined}
        onClick={() => {
          if (!external) setIsMenuOpen(false);
        }}
      >
        {primary ? (
          <NavItem isSelected={isSelected} isHighlighted={isHighlighted}>
            <NavLinkText color={isHighlighted ? colors.green : undefined}>
              {title}
            </NavLinkText>
            {external && (
              <ExternalLink
                color={isHighlighted ? colors.green : undefined}
                style={{
                  marginLeft: 6,
                }}
              />
            )}
          </NavItem>
        ) : (
          <SecondaryMobileNavItem>
            <Title fontSize={16} color={`${colors.primaryText}7A`}>
              {title}
            </Title>
          </SecondaryMobileNavItem>
        )}
      </BaseLink>
    );
  };

  return (
    <HeaderContainer
      ref={headerRef}
      isMenuOpen={isMenuOpen}
      className={isWhiteHeader ? "institutional-header" : ""}
      // className="d-flex align-items-center"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pt-2">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="#">
                {isWhiteHeader ? <HeaderLogoWhite /> : <HeaderLogo />}
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="mr-auto pt-2 nav-links-wrapper">
                  <LinksContainer>
                    {/* {renderLinkItem("Strategies", "/", Boolean(product))} */}
                    {renderLinkItem("Vaults", "/vaults", Boolean(product))}
                    {renderLinkItem(
                      "Analytics",
                      "/analytics",
                      Boolean(portfolio)
                    )}
                    {renderLinkItem(
                      "Portfolio",
                      "/portfolio",
                      Boolean(portfolio)
                    )}
                    {renderLinkItem(
                      "Documentation",
                      "https://blue-swan.gitbook.io/blue-swan-documentation/",
                      Boolean(portfolio),
                      true,
                      true
                    )}
                    {/* {renderLinkItem(
                      "Community",
                      "https://blue-swan.gitbook.io/blue-swan-documentation/",
                      Boolean(portfolio),
                      true,
                      true
                    )} */}
                    <div className="d-flex justify-content-center align-items-center bs-dropdown-header">
                      <FilterDropdown
                        options={[
                          {
                            display: "Discord",
                            value: "VAULTTOKEN",
                            externalLink: false,
                          },
                          {
                            display: "Twitter",
                            value: "RBN",
                            externalLink: true,
                          },
                        ]}
                        value={"Community"}
                        onSelect={(option: any) => {
                          openLink(option);
                        }}
                        buttonConfig={{
                          background: "none",
                          activeBackground: `none`,
                          color: colors.primaryText,
                          header: true,
                          onPage: Boolean(staking),
                        }}
                        menuItemTextConfig={{
                          fontSize: 14,
                          letterSpacing: 1.5,
                        }}
                      />
                    </div>

                    {/* {renderLinkItem("LEND", lendLink, true, true, true, true)} */}
                  </LinksContainer>
                </div>
                <div>
                  {!isWhiteHeader && (
                    <HeaderButtonContainer>
                      <InstitutionalButton
                        to={{ pathname: "/institutional" }}
                        className="mr-5"
                      >
                        For Institutions
                      </InstitutionalButton>
                    </HeaderButtonContainer>
                  )}
                  <div className="btn-connect-wallet d-inline-block">
                    <AccountStatus variant="desktop" />
                  </div>

                  {/* MOBILE MENU */}
                  <MobileOnly>
                    <MenuButton onToggle={onToggleMenu} isOpen={isMenuOpen} />
                    <MobileOverlayMenu
                      className="flex-column align-items-center justify-content-center"
                      isMenuOpen={isMenuOpen}
                      onClick={onToggleMenu}
                      boundingDivProps={{
                        style: {
                          marginRight: "auto",
                        },
                      }}
                    >
                      {renderLinkItem(
                        "VAULTS",
                        "/",
                        Boolean(useRouteMatch({ path: "/", exact: true }))
                      )}
                      {renderLinkItem(
                        "ANALYTICS",
                        "/analytics",
                        Boolean(
                          useRouteMatch({ path: "/analytics", exact: true })
                        )
                      )}
                      {renderLinkItem(
                        "PORTFOLIO",
                        "/portfolio",
                        Boolean(
                          useRouteMatch({ path: "/portfolio", exact: true })
                        )
                      )}
                      {renderLinkItem(
                        "DOCUMENTATION",
                        "/documentation",
                        Boolean(
                          useRouteMatch({ path: "/documentation", exact: true })
                        )
                      )}

                      {renderLinkItem(
                        "VAULT TOKENS",
                        "/staking",
                        Boolean(
                          useRouteMatch({ path: "/staking", exact: true })
                        )
                      )}
                      {/* {renderLinkItem("STAKE RBN", URLS.governance, false, true, true)}
          {renderLinkItem("LEND", lendLink, true, true, true, true)}
          {renderLinkItem("DISCORD", URLS.discord, false, false, true)}
          {renderLinkItem("TWITTER", URLS.twitter, false, false, true)}
          {renderLinkItem("GITHUB", URLS.github, false, false, true)}
          {renderLinkItem("FAQ", URLS.docsFaq, false, false, true)}
          {renderLinkItem("BLOG", URLS.medium, false, false, true)}
          {renderLinkItem("TERMS", URLS.ribbonFinanceTerms, false, false, true)}
          {renderLinkItem(
            "POLICY",
            URLS.ribbonFinancePolicy,
            false,
            false,
            true
          )} */}

                      {/*  {renderLinkItem(
                        "AUCTIONS",
                        URLS.auction,
                        false,
                        false,
                        true
                      )} */}
                    </MobileOverlayMenu>
                  </MobileOnly>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
