import React from "react";
import { Line } from "react-chartjs-2";

interface TimeChartProps {
  showFilters?: boolean;
  title: string;
  subtitle?: string;
  tags?: string[];
  labels?: string[];
  source: any;
  source2?: any;
  yPadding?: number;
  xPadding?: number;
  /* Tmp */
  min?: number;
  max?: number;
  volatileAsset?:string;
  twoAxes?:boolean;
}

const TimeChart: React.FC<TimeChartProps> = (props: TimeChartProps) => {
  const dataSource2 = props.source2 ? props.source2 : [];
  const dataSource =props.source ? props.source : [];
  const volAsset = props?.volatileAsset ? props?.volatileAsset : "asset";
  const options = {
    plugins: {
      /*  datalabels: {
            display: true,
            color: "black",
            formatter: Math.round,
            anchor: "end",
            offset: -20,
            align: "start"
          } */
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {   
          id:"1",       
          ticks: {            
            fontColor: "#BACFFF",
            fontSize: 14,
            padding: props.yPadding || 10,
            callback: function(value:any) {
                return (Math.round(value*100)) + ' %';
              }     
          },
        },   
        {            
            type: 'linear' as const,
            id:"2",
            // display: true,
            position: 'right' as const,     
            ticks: {
              fontColor: "#FFFFFF",
              fontSize: 14,
              padding: props.yPadding || 10,
              callback: function(value:any) {
                if(parseInt(value) >= 1000){
                  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return '$' + value;
                }
              }            
            },
            min: dataSource2.length > 1 ? Math.min.apply(Math, dataSource2):0,
            max: dataSource2.length > 1 ? Math.max.apply(Math, dataSource2):0,
          }       
      ],
      xAxes: [
        {         
          ticks: {
            fontColor: "#BACFFF",
            fontSize: 14,
            padding: props.yPadding || 0,
          },
        },
      ],
    },
  };

  const labels = props.labels;
  let data:any ={
      labels,
      datasets: [
        {
          yAxisID:"1",
          label: "Annualized Returns",
          data: dataSource,
          borderColor: "rgba(189, 247, 255, 1)",
          backgroundColor: "rgba(189, 247, 255, 0)",
        },
        {
          yAxisID:"2",
          label: `Volatile Asset Price - ${volAsset}`,
          data: dataSource2,
          borderColor: "rgba(255, 99, 71, 0.5)",
          backgroundColor: "rgba(189, 247, 255, 0)",
        }   
      ],
    };

  return (
    <>
      <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
        <div className="col-12">
          <div className="row">
            <div className="col-md-12">
              <h5 className="text-uppercase d-inline font-weight-600 mr-3">
                {props.title}
              </h5>


              {props.tags &&
                props.tags.map((t) => {
                  return <div className="tag d-inline">{t}</div>;
                })}
            </div>
            <div className="col-12 py-3">
            <h6 className="text-uppercase d-inline font-weight-600 mr-3">
                {props.subtitle}
              </h6>

            </div>
          </div>
          {props.showFilters && (
            <div className="row">
              <div className="col-md-6 py-4">
                <button className="filter-button active">TVL (USD)</button>
                {/* <button className="filter-button">ETH</button>
                <button className="filter-button">BTC</button>
                <button className="filter-button">DAI</button> */}
              </div>
              <div className="col-md-6 py-4 text-right">
                <button className="filter-button">All</button>
                {/* <button className="filter-button active">1 Year</button>
                <button className="filter-button">90 Day</button>
                <button className="filter-button">30 Day</button>
                <button className="filter-button">7 Day</button> */}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12 py-4">
              {props.twoAxes && dataSource2.length > 0 && dataSource.length>0 &&
                <Line options={options} data={data} />
              }              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeChart;
