import Fantom from "../../assets/icons/blueswan/fantom.svg";
import Avalanche from "../../assets/icons/blueswan/avalanche.svg"
import Optimism from "../../assets/icons/blueswan/optimism.svg"
import { UserVaultEvent } from "shared/lib/models/vault";
import { VaultOptions } from "shared/lib/constants/constants";

type AcitivtyProps = {
  data: UserVaultEvent,
  chain: string
};

const icons = {
  'avalanche': Avalanche,
  'fantom': Fantom,
  'optimism': Optimism
}

const PortfolioActivity = ({userEvents}: {userEvents: AcitivtyProps[]}) => {
  return (
    <>
      <div className="row no-gutters box radius bg box-items py-4 px-3 my-3">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-md-12 mb-2">
              <h6 className="text-uppercase d-inline mr-3">User Activity</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <table className="table activity-table">
                <thead>
                  <tr>
                    <th scope="col">Vault</th>
                    <th scope="col">Chain</th>
                    <th scope="col">Type</th>
                    <th scope="col">Value</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {Array.from({ length: 10 }, (item, index) => {
                    return (
                      <tr key={index}>
                        <td>swan 01</td>
                        <td>
                          <>
                            <img src={Fantom} /> fantom
                          </>
                        </td>
                        <td>{index % 2 ? 'Withdrawal' : "Deposit"} </td>
                        <td>$ {index % 2 ? Math.round(-1 * 50 * Math.random()): 10* Math.round(100 * Math.random())} </td>
                        <td>$4</td>
                        <td>3 / 11 / 2022</td>
                      </tr>
                    );
                  })} */}
                  {
                    userEvents.map((userEvent, index)=> {
                      return (
                        <tr key={index}>
                          <td>swan 01</td>
                          <td>
                            <>
                            {/* @ts-ignore */}
                              <img src={icons[userEvent.chain]} /> {userEvent.chain}
                            </>
                          </td>
                          <td>{userEvent.data.eventType} </td>
                          <td>$ {userEvent.data.usdValue.toFixed(1)} </td>
                          <td>{new Date(+userEvent.data.timestamp*1000).toLocaleDateString()}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioActivity;
